/* eslint-disable no-restricted-imports */
import { Locale } from 'antd/es/locale-provider';
import antdCs from 'antd/es/locale/cs_CZ';
import antdEn from 'antd/es/locale/en_US';
import dayjs from 'dayjs'; // eslint-disable-line no-restricted-imports

import { Lang } from '@cam/app/src/locales';

import 'dayjs/locale/cs';
dayjs.locale('en');

const antdLocaleMap: Record<Lang, Locale> = {
  cs: antdCs,
  en: antdEn,
};

export const getAntdLocale = (locale: Lang) => antdLocaleMap[locale];

export const changeDayjsLocale = (locale: Lang) => dayjs.locale(locale);
