import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface ModalState<Config extends { type: string; props: unknown }> {
  modal: Config | null;
  isConfirmLoading: boolean;
}

export const createModalsSlice = <Config extends { type: string; props: unknown }>(
  name = 'Modals',
  initialState: ModalState<Config> = {
    modal: null,
    isConfirmLoading: false,
  }
) =>
  createSlice({
    name,
    initialState,
    reducers: {
      showModal: (state, { payload }: PayloadAction<Config>) => {
        state.modal = payload as Draft<Config>;
        state.isConfirmLoading = false;
      },
      destroyModal: state => {
        state.modal = null;
        state.isConfirmLoading = false;
      },
      startConfirmLoading: state => {
        state.isConfirmLoading = true;
      },
      setIsConfirmationLoading: (state, { payload }: PayloadAction<boolean>) => {
        state.isConfirmLoading = payload;
      },
    },
  });
