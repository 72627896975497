import { InitOptions, TFunction } from 'i18next';

export enum Lang {
  cs = 'cs',
  en = 'en',
}
export const langMap: Record<Lang, string> = {
  cs: 'cs',
  en: 'en',
};
export const fallbackLng = Lang.en;

export const namespaces = [
  'account',
  'cashRegister',
  'common',
  'company',
  'customers',
  'dashboard',
  'events',
  'employees',
  'reports',
  'signup',
  'signin',
  'users',
] as const;

export const useLanguageOptions = (t: TFunction) => {
  return Object.values(Lang).map<{ value: string; label: string }>(lang => ({
    value: langMap[lang],
    // i18next-extract-mark-context-next-line ["cs", "en"]
    label: t('common:languages.lang', { context: lang }),
  }));
};

export const config: InitOptions = {
  ns: namespaces,
  fallbackLng: false,
  // fallbackLng, TODO @psenicka: swithc back to fallback english
  lng: fallbackLng,
  defaultNS: 'common',
  returnEmptyString: false,
  compatibilityJSON: 'v3',
};
