import {
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { pull } from 'lodash';

export type EntitySliceState<T> = {
  data: EntityState<T>;
  isInitialized: boolean;
  isLoading: boolean;
  isCreating: boolean;
  loadingIds: (string | number)[];
  updatingIds: (string | number)[];
  hasError: boolean;
};

export const createEntitySlice = <T, Reducers extends SliceCaseReducers<EntitySliceState<T>>>(
  name: string,
  entityAdapter: EntityAdapter<T>,
  reducers: ValidateSliceCaseReducers<
    EntitySliceState<T>,
    Reducers
  > = {} as ValidateSliceCaseReducers<EntitySliceState<T>, Reducers>
) => {
  const initialState: EntitySliceState<T> = {
    data: entityAdapter.getInitialState(),
    isInitialized: false,
    isLoading: false,
    isCreating: false,
    loadingIds: [],
    updatingIds: [],
    hasError: false,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
        state.isLoading = payload;
      },
      setIsCreating: (state, { payload }: PayloadAction<boolean>) => {
        state.isCreating = payload;
      },
      getDataRequest: (state, { payload }: PayloadAction<string[] | undefined>) => {
        state.isLoading = true;
        payload && state.loadingIds.push(...payload);
      },
      getDataSuccess: {
        prepare: (payload: T | T[], meta: { override?: boolean } = {}) => ({ payload, meta }),
        reducer: (
          state,
          { payload, meta }: PayloadAction<T | T[], string, { override?: boolean }>
        ) => {
          if (Array.isArray(payload)) {
            meta.override
              ? entityAdapter.setAll(state.data as EntityState<T>, payload)
              : entityAdapter.upsertMany(state.data as EntityState<T>, payload);
          } else {
            meta.override
              ? entityAdapter.setAll(state.data as EntityState<T>, [payload])
              : entityAdapter.upsertOne(state.data as EntityState<T>, payload);
          }
          const ids = Array.isArray(payload)
            ? payload.map(entityAdapter.selectId)
            : [entityAdapter.selectId(payload)];
          state.isInitialized = true;
          state.isLoading = false;
          pull(state.loadingIds, ...ids);
          state.hasError = false;
        },
      },
      getDataFailure: (state, { payload }: PayloadAction<string[] | undefined>) => {
        state.isInitialized = true;
        state.isLoading = false;
        payload && pull(state.loadingIds, ...payload);
        state.hasError = true;
      },
      updateDataRequest: (state, { payload }: PayloadAction<string[] | 'All'>) => {
        const updatedEntityIds = payload === 'All' ? state.data.ids : payload;
        state.updatingIds.push(...updatedEntityIds);
      },
      updateDataSuccess: (
        state,
        { payload }: PayloadAction<Partial<T> & { entityIds: string[] | 'All' }>
      ) => {
        const { entityIds, ...changes } = payload;
        const updatedEntityIds = entityIds === 'All' ? state.data.ids : entityIds;
        pull(state.updatingIds, ...updatedEntityIds);
        entityAdapter.updateMany(
          state.data as EntityState<T>,
          updatedEntityIds.map(id => ({ id, changes: changes as unknown as Partial<T> }))
        );
      },
      updateDataFailure: (state, { payload }: PayloadAction<string[] | 'All'>) => {
        const updatedEntityIds = payload === 'All' ? state.data.ids : payload;
        pull(state.updatingIds, ...updatedEntityIds);
      },
      removeEntities: (state, { payload }: PayloadAction<string[]>) => {
        entityAdapter.removeMany(state.data as EntityState<T>, payload);
      },
      reset: () => {
        return initialState;
      },
      ...reducers,
    },
  });
};
