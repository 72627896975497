import { RootState } from '@cam/app/src/redux/reducer';
import { createBooleanSlice } from '@cam/redux/slices';

const reducerName = 'Users/Global';

export const {
  actions: { set: setUserProfileVisible },
  reducer: userProfileVisibleReducer,
} = createBooleanSlice(`${reducerName}/userProfileVisible`, false);

export default userProfileVisibleReducer;

export const isUserProfileVisible = (state: RootState) => state.Users.userProfileVisible;
