import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '@cam/atomic';

const Badge = styled.div`
  && {
    border-radius: 4px;
    background-color: ${props => props.theme.colors.brand};
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    padding: 1px 6px;
    margin: 0 12px;
  }
`;

const Label = styled(Text).attrs({ size: 'sm' })`
  color: ${props => props.theme.colors.brand};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const BetaSeparator = styled(Divider).attrs(({ type }) => ({
  type: type || 'vertical',
}))`
  && {
    border-color: ${props => props.theme.colors.neutral.dark.disabled};
    font-size: ${props => props.theme.fontSizes.md}px;
    margin: 0 4px;
  }
`;

export const BetaBadge: React.FC<{ text?: string; withTooltip?: boolean }> = ({
  text,
  withTooltip = false,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={withTooltip && t('common:betaTooltip')} placement="right">
      <Badge>{text || t('common:beta')}</Badge>
    </Tooltip>
  );
};

export const BetaLabel: React.FC<{ text?: string }> = ({ text }) => {
  const { t } = useTranslation();
  return <Label>{text || t('common:beta')}</Label>;
};
