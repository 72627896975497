import { message, MessageArgsProps } from 'antd';
import { FormikErrors, useFormikContext } from 'formik';
import { TFunction } from 'i18next';
import { get, isEmpty, isEqual, omit } from 'lodash';

import { EventDetailResource } from '@cam/firebase/resource/Event';

export const generateEventPersistId = (eventId: string) => `event-edit-${eventId}`;

export const removeEventIdFromLocalStorage = (eventId: string) =>
  localStorage.removeItem(generateEventPersistId(eventId));

export const saveEventIntoLocalStorage = ({
  errMessage,
  eventId,
  data,
}: {
  errMessage: React.ReactNode;
  eventId: string;
  data: string;
}) => {
  const persistId = generateEventPersistId(eventId);
  try {
    localStorage.setItem(persistId, data);
  } catch {
    message.warning(errMessage);
  }
};

const ALERT_DURATION = 3;
enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  LOADING = 'loading',
}

export const isValueChanged = (
  initialValues: EventDetailResource,
  newValues: EventDetailResource,
  path: string,
  isTable?: boolean
) => {
  const originalValue = get(initialValues, path);
  const newValue = get(newValues, path);

  if (isTable) {
    return !isEqual(omit(originalValue, ['order', 'key']), omit(newValue, ['order', 'key']));
  }
  return !isEqual(originalValue, newValue);
};

export const useFormikValues = () => useFormikContext<EventDetailResource>();

export const getDataFromObjects = (
  t: TFunction,
  oldData?: EventDetailResource,
  newData?: EventDetailResource,
  errors?: FormikErrors<EventDetailResource>
): {
  isChanged: boolean;
  error?: MessageArgsProps;
} => {
  if (isEmpty(errors) && oldData && newData) {
    return {
      isChanged: !isEqual(newData, oldData),
      error: undefined,
    };
  }

  return {
    isChanged: false,
    error: {
      content: t('events:detail.save.form.errors'),
      duration: ALERT_DURATION,
      key: 'eventErrorMessage',
      type: AlertType.WARNING,
    },
  };
};
