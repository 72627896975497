import { navigate } from '@reach/router';
import { combineReducers } from '@reduxjs/toolkit';

import { Routes } from '@cam/app/src/constants/routes';
import { removeEventIdFromLocalStorage } from '@cam/app/src/features/events/utils';
import { getSelectedCompany } from '@cam/app/src/redux/Company/selectors';
import { getCustomer } from '@cam/app/src/redux/Customers/selectors';
import { getEmployee, getPersonnel } from '@cam/app/src/redux/Employees/selectors';
import { eventDetailAdapter } from '@cam/app/src/redux/Events/adapters';
import { eventListActions, fetchEventList } from '@cam/app/src/redux/Events/eventList';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { AppThunk } from '@cam/app/src/redux/store';
import { dateISOToTimestamp } from '@cam/app/src/utils/date';
import { CashRegisterListResource, DefaultCategories } from '@cam/firebase/resource/CashRegister';
import { Currency } from '@cam/firebase/resource/Common';
import { Salary } from '@cam/firebase/resource/Company';
import {
  EmployeePaymentRow,
  EmployeePaymentTable,
  EventDetailResource,
  EventResource,
} from '@cam/firebase/resource/Event';
import { createEntitySlice } from '@cam/redux/slices';

const reducerName = 'Events/Detail';

export const { actions: dataActions, reducer: dataReducer } = createEntitySlice(
  `${reducerName}/eventDetail`,
  eventDetailAdapter
);
export const fetchEventDetail =
  (eventId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(dataActions.getDataRequest());
      const data = await api.Event.fetchEventDetail(eventId);
      if (!!data) {
        dispatch(dataActions.getDataSuccess(data));
      } else {
        dispatch(dataActions.getDataFailure());
      }
    } catch (e) {
      console.log('🚀 ~ file: eventDetail.ts ~ line 34 ~ e', e);
      dispatch(dataActions.getDataFailure());
    }
  };

export const updateEvent =
  (event: EventDetailResource, onSuccess?: () => void): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      const {
        companyId,
        productionManager: productionManagerId,
        personnel: personnelIds,
        customer: customerId,
        date,
        eventId,
        middle,
        enumeration,
        ...rest
      } = event;
      const productionManager = getEmployee(getState(), productionManagerId);
      const personnel = (!!personnelIds && getPersonnel(getState(), personnelIds)) || undefined;
      const customer = (!!customerId && getCustomer(getState(), customerId)) || undefined;
      const companyInfo = getSelectedCompany(getState());

      // Employee salaries table
      const employeesSalaryCash: EmployeePaymentTable = {};

      if (Array.isArray(personnel) && Array.isArray(personnelIds)) {
        const salaries = await api.Company.Salary.fetchSalaries(
          personnel.map(employee => employee.salaryId || '')
        );
        personnel.forEach(employee => {
          const newSalary = salaries.find(salary => salary.salaryId === employee.salaryId);
          employeesSalaryCash[employee.userId] =
            middle?.employeesSalaryCash?.[employee.userId] ||
            ({
              amount: 0,
              salary: {
                amount: newSalary?.salary.amount,
                perTimeUnit: newSalary?.salary.perTimeUnit,
              } as Salary,
            } as EmployeePaymentRow);
        });
      }

      const newMiddle = { ...middle, employeesSalaryCash };

      // CashRegistry record
      let cashRecordId = '';
      if (enumeration?.submit) {
        const newCashRegisterRecord: Omit<CashRegisterListResource, 'cashRegisterRecordId'> = {
          amount: enumeration.cashRegisterIncome,
          amountWithoutDph: { amount: 0, currency: companyInfo?.currency || Currency.CZK },
          cashRegisterBalance: {
            amount: 0,
            currency: companyInfo?.currency || Currency.CZK,
            date: dateISOToTimestamp(new Date()),
          },
          cashRegisterCorrection: {
            amount: 0,
            currency: companyInfo?.currency || Currency.CZK,
            date: dateISOToTimestamp(new Date()),
          },
          categoryKey: DefaultCategories.EVENT,
          date: dateISOToTimestamp(new Date()),
          dph: { amount: 0, currency: companyInfo?.currency || Currency.CZK },
          description: `${rest.name} event money`,
        };
        if (enumeration.cashRegisterId) {
          cashRecordId = enumeration.cashRegisterId;
          await api.CashRegister.updateCashRegisterRecord(companyId, {
            ...newCashRegisterRecord,
            cashRegisterRecordId: enumeration.cashRegisterId,
          });
        } else {
          cashRecordId = await api.CashRegister.createCashRegisterRecord(
            companyId,
            newCashRegisterRecord
          );
        }
      } else if (!enumeration?.submit && enumeration?.cashRegisterId) {
        await api.CashRegister.deleteCashRegisterRecord(companyId, enumeration.cashRegisterId);
      }

      const newDetail = {
        ...rest,
        enumeration: { ...enumeration, cashRegisterId: cashRecordId },
        middle: newMiddle,
        companyId,
        eventId,
        productionManager,
        personnel,
        customer,
        date: dateISOToTimestamp(date),
      } as EventResource;

      productionManager && (await api.Event.updateEvent(newDetail));
      dispatch(fetchEventDetail(eventId));
      dispatch(fetchEventList(companyId));
      notifications().events.updateEvent.success();
      onSuccess?.();
      removeEventIdFromLocalStorage(eventId);
    } catch (e) {
      console.log('🚀 ~ file: eventDetail.ts ~ line 98 ~ e', e);
      notifications().events.updateEvent.failure();
    }
  };

export const deleteEvent =
  (eventId: string, paymentId?: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      await api.Event.deleteEvent(eventId);
      !!paymentId && (await api.Transaction.deleteTransaction(paymentId));
      dispatch(eventListActions.dataActions.removeEntities([eventId]));
      dispatch(eventListActions.dataActions.updateDataSuccess({ entityIds: [eventId] }));
      navigate(Routes.EVENTS.BASE);
      notifications().events.removeEvent.success();
    } catch {
      notifications().events.removeEvent.failure();
    } finally {
      dispatch(destroyModal());
    }
  };

export const eventDetailReducer = combineReducers({
  detail: dataReducer,
});
