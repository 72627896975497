import { ColumnProps, ColumnType } from 'antd/es/table';
import { SorterResult, ColumnFilterItem } from 'antd/es/table/interface';

import { StringKeys } from '@cam/app/src/utils/typescript';

export type TypedColumnProps<T> = ColumnProps<T> & { dataIndex: StringKeys<T> };

export type TypedColumnType<T> = ColumnType<T> & { dataIndex: StringKeys<T> };

export type TypedSorterResult<T> = SorterResult<T> & { column: { dataIndex: StringKeys<T> } };

export const translatedListFilter = <T extends string | number>(
  list: T[],
  translations: Record<T, string>
): ColumnFilterItem[] => list.map(value => ({ text: translations[value], value }));

export const listFilter = (list: string[]): ColumnFilterItem[] =>
  list.map(value => ({ text: value, value }));

export const renderTranslatedValue = <T extends string | number>(
  value: T | undefined | null,
  translations: Record<T, string>
) => value && translations[value];

export const getPopupContainer = (trigger?: HTMLElement): HTMLElement => {
  let container = document.body;
  if (!trigger?.parentElement) return container;
  // it might be possible to apply to other elements but it cannot be set globally because it breaks stuff (menus, tooltips etc.)
  if (trigger.classList.contains('ant-select-selector')) {
    container = trigger.parentElement;
  }
  return container;
};
