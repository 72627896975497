import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

export const createValueSlice = <T, Reducers extends SliceCaseReducers<T> = SliceCaseReducers<T>>(
  name: string,
  initialState: T,
  reducers: ValidateSliceCaseReducers<T, Reducers> = {} as ValidateSliceCaseReducers<T, Reducers>
) =>
  createSlice({
    name,
    initialState,
    reducers: {
      set: {
        reducer: (_, { payload }: PayloadAction<T>) => payload,
        prepare: (payload: T) => ({
          payload,
        }),
      },
      reset: () => initialState,
      ...reducers,
    },
  });

export const createBooleanSlice = (name: string, initialState: boolean) =>
  createValueSlice(name, initialState, {
    toggle: {
      reducer: state => !state,
      prepare: () => ({ payload: undefined }),
    },
  });
