// eslint-disable-next-line @typescript-eslint/ban-types
export type AllModalProps = { modalType: ModalType };

export enum ModalType {
  ActivateEmployee = 'activate_employee',
  CreateCompany = 'create_company',
  CreateCustomer = 'create_customer',
  DeleteCategory = 'delete_category',
  DeleteCompany = 'delete_company',
  DeleteEvent = 'delete_event',
  EmployeeRoles = 'employee_roles',
  EventSave = 'event_save',
  RemoveCompanyAccess = 'remove_company_access',
  RemoveInvitedEmployee = 'remove_invited_employee',
  RemoveEmployee = 'remove_employee',
  SuspendEmployee = 'suspend_employee',
}
