import i18n, { TFunction } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { config } from '@cam/app/src/locales';

export const createI18next = () =>
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      ...config,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        requestOptions: {
          cache: process.env.NODE_ENV === 'development' ? 'reload' : 'force-cache',
        },
        queryStringParams: {
          ...(process.env.REACT_APP_BUILD_NUMBER && {
            v: process.env.REACT_APP_BUILD_NUMBER,
          }),
        },
      },
      interpolation: { escapeValue: false },
    });

let t: TFunction | undefined;
export const initI18next = async () => {
  return t || (t = await createI18next());
};
