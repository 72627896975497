import { StringKeys } from '@cam/app/src/utils/typescript';
import { EventList, EventListSorted, EventListFilterResource } from '@cam/firebase/resource/Event';
import { FilterType, pickFirst } from '@cam/redux/table/filter';

export const serializeFilter = (filter: FilterType<EventList>): EventListFilterResource => ({
  name: pickFirst(filter.name),
  customer: pickFirst(filter.customer),
  productionManager: pickFirst(filter.productionManager),
  eventId: pickFirst(filter.eventId),
});

export const sortMap: Partial<Record<StringKeys<EventList>, EventListSorted>> = {
  date: EventListSorted.DATE,
  hosts: EventListSorted.HOSTS,
};
