import { combineReducers } from '@reduxjs/toolkit';

import { eventDetailReducer } from '@cam/app/src/redux/Events/eventDetail';
import {
  dateRangeReducer,
  eventListReducer,
  eventListThunks,
  fetchingAditionalEventListReducer,
  firebasePaginationActions,
  firebasePaginationReducer,
} from '@cam/app/src/redux/Events/eventList';
import { eventWidgetActions, eventWidgetReducer } from '@cam/app/src/redux/Events/eventWidget';
import { AppThunk } from '@cam/app/src/redux/store';

export const reset = (): AppThunk => dispatch => {
  dispatch(eventListThunks.reset());
  dispatch(firebasePaginationActions.reset());
  dispatch(eventWidgetActions.reset());
};

const reducer = combineReducers({
  eventList: eventListReducer,
  eventDetail: eventDetailReducer,
  eventWidget: eventWidgetReducer,
  firebasePagination: firebasePaginationReducer,
  fetchingAdditionalEventList: fetchingAditionalEventListReducer,
  dateRange: dateRangeReducer,
});

export default reducer;
