import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  FailureArgs,
  FailureMessage,
  SuccessArgs,
  SuccessMessage,
  MessageProps,
} from '@cam/app/src/features/notifications/types';

const Failure: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return <>{children || t('common:messages.failure')}</>;
};

export const showFailure =
  <FailureContext extends unknown>(message?: FailureArgs<FailureContext>, props?: MessageProps) =>
  (context?: FailureContext) => {
    const msg = message instanceof Function ? message(context) : message;
    antdMessage.error({ ...props, content: <Failure>{msg}</Failure> });
  };

const Success: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return <>{children || t('common:messages.success')}</>;
};

export const showSuccess =
  <SuccessContext extends unknown>(message?: SuccessArgs<SuccessContext>, props?: MessageProps) =>
  (context?: SuccessContext) => {
    const msg = message instanceof Function ? message(context) : message;
    antdMessage.success({ ...props, content: <Success>{msg}</Success> });
  };

export function createMessage<SuccessContext>(args: {
  success: SuccessArgs<SuccessContext> | true;
  failure?: never;
  options?: MessageProps;
}): SuccessMessage<SuccessContext>;
export function createMessage<FailureContext>(args: {
  success?: never;
  failure: FailureArgs<FailureContext> | true;
  options?: MessageProps;
}): FailureMessage<FailureContext>;
export function createMessage<SuccessContext, FailureContext>(args: {
  success: SuccessArgs<SuccessContext> | true;
  failure: FailureArgs<FailureContext> | true;
  options?: MessageProps;
}): SuccessMessage<SuccessContext> & FailureMessage<FailureContext>;

export function createMessage<SuccessContext, FailureContext>({
  success,
  failure,
  options,
  key,
}: {
  // use true for default msg, string for custom msg, undefined for no msg
  success?: SuccessArgs<SuccessContext> | true;
  failure?: FailureArgs<FailureContext> | true;
  options?: MessageProps;
  key?: string;
}) {
  return {
    ...(success && {
      success: showSuccess(success === true ? undefined : success, { ...options, key }),
    }),
    ...(failure && {
      failure: showFailure(failure === true ? undefined : failure, { ...options, key }),
    }),
  };
}
