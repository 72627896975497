import { Router } from '@reach/router';
import { lazy } from 'react';

import { AppProvider, AuthAppProvider } from '@cam/app/src/components/AppProvider';
import { Route } from '@cam/app/src/components/Route';
import { Login } from '@cam/app/src/features/oauth/Login';
import store from '@cam/app/src/redux/store';
import { initI18next } from '@cam/app/src/utils/i18n';
// this creates a circular dependency if not imported last
// eslint-disable-next-line import/order
import { enhanceStore } from '@cam/app/src/redux/appStore';

const AppRouter = lazy(() => import(/* webpackChunkName: "app"*/ '@cam/app/src/AppRouter'));

initI18next();
enhanceStore();

const useAppInitEvent = () => {
  console.log('APP INITIALIZED');
};

export const App: React.FC = () => {
  useAppInitEvent();

  return (
    <AppProvider reduxStore={store}>
      <Router>
        <Route path="*" component={<AuthAppProvider app={<AppRouter />} />} />
        <Route path="/login" component={<Login />} />
      </Router>
    </AppProvider>
  );
};
