export const Routes = {
  CASH_REGISTER: {
    BASE: '/cashRegister',
    CREATE: 'create',
    CATEGORIES: 'categories',
    DETAIL: ':recordId',
  },
  COMPANY: {
    BASE: '/company',
  },
  DASHBOARD: {
    BASE: '/dashboard',
    CREATE: 'create',
  },
  EVENTS: {
    BASE: '/events',
    CREATE: 'create',
    DETAIL: ':eventId',
    TAB: ':tabId',
  },
  EMPLOYEES: {
    BASE: '/employees',
    INVITE: 'invite',
    DETAIL: ':userId',
  },
  INVITATION: {
    BASE: '/invitation',
  },
  LOGIN: {
    BASE: '/login',
  },
  SIGNUP: {
    BASE: '/signup',
  },
  SETTINGS: {
    BASE: '/settings',
    TAB: ':tabId',
  },
} as const;
