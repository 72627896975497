import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from '@cam/app/src/constants/routes';
import { Logout } from '@cam/app/src/features/oauth/Logout';
import { isLoggingOut } from '@cam/app/src/features/oauth/utils';
import { fetchCurrentUser, reset as authReset } from '@cam/app/src/redux/Auth';
import authSelectors from '@cam/app/src/redux/Auth/selectors';
import { auth } from '@cam/firebase/clientApp';

export const Secure: React.FC<SecureProps> = props => {
  const dispatch = useDispatch();
  const isLoadingUser = useSelector(authSelectors.getIsLoading);
  const [isFirebaseAuthInitialized, setFirebaseAuthInitialized] = useState(false);
  const isLoading = isLoadingUser || !isFirebaseAuthInitialized;
  const { children, authenticating: Authenticating } = props as SecureProps;

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setFirebaseAuthInitialized(true);
      if (user) {
        dispatch(fetchCurrentUser());
      } else {
        dispatch(authReset());
        navigate(Routes.LOGIN.BASE, { state: { redirectUrl: window.location.href } });
      }
    });
  }, [dispatch]);

  if (isLoggingOut) {
    return <Logout />;
  }

  return isLoading ? <Authenticating /> : <>{children}</>;
};

type SecureProps = {
  authenticating: React.ComponentType;
  children: React.ReactNode;
};
