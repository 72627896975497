/* eslint-disable no-template-curly-in-string */
import { ValidateMessages } from 'rc-field-form/es/interface';
import { useMemo } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

enum Interpolation {
  Label = '${label}',
  Name = '${name}',
  Min = '${min}',
  Max = '${max}',
  Length = '${len}',
  Enum = '${enum}',
}

const createMessages = (t: TFunction): ValidateMessages => ({
  default: t('common:validation.default', { formInput: Interpolation.Label }),
  required: t('common:validation.required', { formInput: Interpolation.Label }),
  whitespace: t('common:validation.required', { formInput: Interpolation.Label }),
  string: {
    min: t('common:validation.minChars', {
      formInput: Interpolation.Label,
      count: Interpolation.Min as unknown as number,
    }),
    max: t('common:validation.maxChars', {
      formInput: Interpolation.Label,
      count: Interpolation.Max as unknown as number,
    }),
  },
  types: {
    email: t('common:validation.email'),
  },
});

export const useFormMessages = () => {
  const { t } = useTranslation();
  return useMemo(() => createMessages(t), [t]);
};
