import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/es/card';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { BackgroundVariant, Colors } from '@cam/theme/types';

const StyledCard = styled(AntdCard)<{ $background: BackgroundVariant; $titleColor?: Colors }>`
  .ant-card-head,
  .ant-card-meta-title {
    color: ${props =>
      props.$titleColor
        ? props.theme.colors[props.$titleColor]
        : props.theme.colors.neutral.light.primary};
    border-bottom: none;
  }

  &.ant-card,
  .ant-card-body,
  .ant-card-actions,
  .ant-card-meta-description {
    background-color: ${props => props.theme.colors.neutral.light[props.$background]};
    color: ${props => props.theme.colors.neutral.light.primary};
  }

  &.ant-card-bordered,
  .ant-card-head,
  .ant-card-actions,
  .ant-card-actions > li:not(:last-child) {
    border-color: ${props => transparentize(0.07, props.theme.colors.neutral.light.border)};
  }

  .ant-card-head + .ant-card-body:not(:empty) {
    /* head has margin-bottom -1px and the layout breaks if we remove it */
    margin-top: 1px;
  }

  .ant-card-head-wrapper {
    align-items: start;
  }

  .ant-card-actions > li > span,
  .ant-card-actions > li > span a {
    color: ${props => props.theme.colors.neutral.light.secondary};

    &:hover {
      color: ${props => props.theme.colors.neutral.light.primary};
    }
  }

  .ant-card-body:empty {
    padding: 0;

    & + .ant-card-actions:nth-child(2) {
      border-top-width: 0;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }

  .ant-card-head,
  .ant-card-body:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .ant-card-actions,
  .ant-card-body:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &&.ant-card-type-inner,
  &&.ant-card-small {
    .ant-card-head-title,
    .ant-card-extra {
      padding: 12px 0;
    }
  }
`;

const BaseCard: React.FC<CardProps & { background?: BackgroundVariant; titleColor?: Colors }> = ({
  background = 'bg',
  titleColor,
  ...props
}) => <StyledCard $background={background} $titleColor={titleColor} {...props} />;

// re-export static properties of antd card
export const Card = Object.assign(BaseCard, {
  Grid: AntdCard.Grid,
  Meta: AntdCard.Meta,
});
