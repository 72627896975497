import { TFunction } from 'i18next';

import { createMessage } from '@cam/app/src/features/notifications/defaults';

export const messages = (t: TFunction) => ({
  createCompany: createMessage({
    failure: t('users:messages.create.company.failure') as string,
  }),
  updateProfile: createMessage({
    success: t('users:messages.profile.update.success') as string,
    failure: t('users:messages.profile.update.failure') as string,
  }),
  removeAccess: createMessage({
    success: t('users:messages.remove.company.success') as string,
    failure: t('users:messages.remove.company.failure') as string,
  }),
});
