import { combineReducers, Action, EntityAdapter, ThunkAction } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';

import { createEntitySlice } from '@cam/redux/slices/entity';
import { createFilter, FilterType } from '@cam/redux/table/filter';
import { createPagination } from '@cam/redux/table/pagination';
import { createRows } from '@cam/redux/table/rows';
import { createSorter } from '@cam/redux/table/sorter';
import { mapRowIds } from '@cam/redux/utils';

type Thunk = ThunkAction<void, unknown, unknown, Action<string>>;

export const createTableActions = <T>({
  reducerName,
  adapter,
  initialFilter = {},
  initialSorter = { order: null },
}: {
  reducerName: string;
  adapter: EntityAdapter<T>;
  initialFilter?: FilterType<T>;
  initialSorter?: SorterResult<T>;
}) => {
  const { actions: dataActions, reducer: dataReducer } = createEntitySlice(
    reducerName + '/data',
    adapter
  );
  const { actions: paginationActions, reducer: paginationReducer } = createPagination(reducerName);
  const { actions: rowsActions, reducer: rowsReducer } = createRows(reducerName);

  const { actions: filterActions, reducer: filterReducer } = createFilter(
    reducerName,
    initialFilter
  );

  const { actions: sorterActions, reducer: sorterReducer } = createSorter(
    reducerName,
    initialSorter
  );

  const reset = (): Thunk => dispatch => {
    dispatch(dataActions.reset());
    dispatch(filterActions.reset());
    dispatch(sorterActions.reset());
    dispatch(paginationActions.reset());
    dispatch(rowsActions.reset());
  };

  const setData =
    (data: T[], count: number, meta: { override?: boolean } = {}): Thunk =>
    dispatch => {
      dispatch(dataActions.getDataSuccess(data, meta));
      dispatch(rowsActions.setVisibleRows(mapRowIds(data, adapter)));
      dispatch(paginationActions.setTotal(count));
      dispatch(filterActions.setCount(count));
    };

  return {
    actions: {
      dataActions,
      filterActions,
      paginationActions,
      rowsActions,
      sorterActions,
    },
    reducers: {
      dataReducer,
      filterReducer,
      paginationReducer,
      rowsReducer,
      sorterReducer,
    },
    reducer: combineReducers({
      data: dataReducer,
      filter: filterReducer,
      pagination: paginationReducer,
      rows: rowsReducer,
      sorter: sorterReducer,
    }),
    thunks: {
      reset,
      setData,
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
class TableActionsWrapper<T> {
  wrapper = () => createTableActions<T>(null as any);
}
export type TableActions<T> = ReturnType<TableActionsWrapper<T>['wrapper']>;
