import { Typography as AntdTypography } from 'antd';
import Base, { BaseType } from 'antd/es/typography/Base';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import { TextProps } from 'antd/es/typography/Text';
import { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';
import styled, { css, DefaultTheme } from 'styled-components';

import { RequireProps, StyledProps } from '@cam/app/src/utils/typescript';
import { FontSize } from '@cam/theme/types';

const sizeToPx = (theme: DefaultTheme, size?: FontSize): number =>
  size ? theme.fontSizes[size] : theme.fontSizes.base;

const textStyles = css<TextStyleProps>`
  && {
    font-size: ${props =>
      props.$size === 'inherit' ? 'inherit' : sizeToPx(props.theme, props.$size)}px;
    ${props =>
      props.$theme === 'dark' &&
      css`
        && {
          color: ${props.theme.colors.neutral.dark[props.type || 'primary']};
        }
        &&.ant-typography-disabled {
          color: ${props.theme.colors.neutral.dark.disabled};
        }
        &&.ant-typography code {
          background: ${props.theme.colors.neutral.dark.divider};
        }
      `}
  }
`;

const noWrapStyles = css<{ $noWrap?: boolean }>`
  ${props =>
    props.$noWrap &&
    css`
      && {
        white-space: nowrap;
      }
    `}
`;

const SText = styled(AntdTypography.Text)`
  ${textStyles}
  ${noWrapStyles}
`;
const STitle = styled(AntdTypography.Title)<TitleProps>`
  ${textStyles}
  ${noWrapStyles}
`;
const SParagraph = styled(Base).attrs({ component: 'p' })`
  ${textStyles}
  ${noWrapStyles}
`;

type TextStylesLight = {
  theme?: 'light';
  type?: BaseType;
  size?: FontSize | 'inherit';
  noWrap?: boolean;
};

type TextStylesDark = {
  theme?: 'dark';
  type?: 'secondary';
  size?: FontSize | 'inherit';
  noWrap?: boolean;
};

type TextStyles = TextStylesLight | TextStylesDark;
type TextStyleProps = StyledProps<TextStyles> & Pick<TextStyles, 'type'>;
type TitleProps = RequireProps<AntdTitleProps, 'level'>;

const applyTextStyles: <T>(
  Component: React.ComponentType<T & TextStyleProps>
) => React.FC<T & TextStyles> =
  Component =>
  ({ theme = 'light', noWrap, size, type, ...rest }) =>
    <Component $theme={theme} $size={size} type={type} $noWrap={noWrap} {...rest} />;

export const Title = applyTextStyles<TitleProps>(STitle);
export const Text = applyTextStyles<TextProps>(SText);
export const Paragraph = applyTextStyles<ParagraphProps>(SParagraph);

export { TextLink, ExternalLink, OptionalLink, NavLink } from './Link';
