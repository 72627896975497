import { Timestamp } from 'firebase/firestore';

import { DateType } from '@cam/firebase/../app/src/utils/date';
import { Amount } from '@cam/firebase/resource/CashRegister';
import { SortDirection } from '@cam/firebase/resource/Common';
import { Salary } from '@cam/firebase/resource/Company';
import { Employee } from '@cam/firebase/resource/Employee';
export interface EventList {
  eventId: string;
  name: string;
  date: string;
  eventType: EventType;
  productionManager: Employee;
  hosts?: number;
  customer?: Customer;
}

export interface EventResource extends Omit<EventList, 'date'> {
  companyId: string;
  date: Timestamp;
  description?: string;
  personnel?: Employee[];
  paymentId?: string;
  agreement?: Agreement;
  walletBefore?: Wallet;
  middle?: Middle;
  walletAfter?: Wallet;
}

export interface EventDetailResource {
  eventId: string;
  companyId: string;
  name: string;
  eventType: EventType;
  date: DateType;
  productionManager: string;
  personnel?: string[];
  agreement?: Agreement;
  customer?: string;
  description?: string;
  hosts?: number;
  walletBefore?: Wallet;
  middle?: Middle;
  walletAfter?: Wallet;
  enumeration?: {
    cashRegisterId?: string;
    submit: boolean;
    cashRegisterIncome: Amount;
  };
}

export interface Middle {
  deposit: number;
  rentSpaceCash: number;
  rentSpaceCard: number;
  salesCash: number;
  salesCard: number;
  invoice: number;
  employeesSalaryCash?: EmployeePaymentTable;
  otherCosts?: number;
}

export interface EmployeePaymentRow {
  salary?: Salary;
  amount?: number;
}
export interface EmployeePaymentTable {
  [key: string]: EmployeePaymentRow;
}

export interface Wallet {
  total: Amount;
  [key: number]: WalletBill;
}

export interface WalletBill {
  bill: Amount;
  quantity: number;
}

export type CreateEventResource = Omit<EventResource, 'eventId' | 'companyId'> & {
  middle?: Middle;
};

export interface EventListFilterResource {
  eventId?: string;
  name?: string;
  productionManager?: string;
  customer?: Customer;
}

export enum EventListSorted {
  DATE = 'DATE',
  HOSTS = 'HOSTS',
}

interface SortRequestEventListSorted {
  direction: SortDirection;
  field: EventListSorted;
}

interface FilterResourceEventListFilterResource {
  filterAnd?: Array<EventListFilterResource>;
}

interface SortedPageEventListSorterResource {
  page: number;
  size: number;
  sorting: Array<SortRequestEventListSorted>;
}

export interface PagedEventListFilterResourceSorted {
  filter: FilterResourceEventListFilterResource;
  sortedPage: SortedPageEventListSorterResource;
}

export enum EventType {
  BIRTHDAY = 'BIRTHDAY',
  CONCERT = 'CONCERT',
  FESTIVAL = 'FESTIVAL',
  CHILL = 'CHILL',
  OTHER = 'OTHER',
}

export enum Agreement {
  WITHOUT = 'WITHOUT',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
}

export interface Customer {
  customerId: string;
  email?: string;
  phone?: string;
  name?: string;
}

export interface CustomerResource extends Customer {
  companyId: string;
}
