import { navigate } from '@reach/router';
import { combineReducers } from '@reduxjs/toolkit';

import { Routes } from '@cam/app/src/constants/routes';
import { INVITATION_ERROR_CODES } from '@cam/app/src/features/invitation/Invitation';
import { getUser } from '@cam/app/src/redux/Auth/selectors';
import { fetchCompaniesList } from '@cam/app/src/redux/Company';
import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { fetchEmployees } from '@cam/app/src/redux/Employees';
import { reducerName } from '@cam/app/src/redux/Employees/adapters';
import { AppThunk } from '@cam/app/src/redux/store';
import { generatePermissionsMatrix } from '@cam/app/src/utils/permissionsMatrix';
import { EmployeeStatus, InvitationRequestResource } from '@cam/firebase/resource/Employee';
import { createBooleanSlice, createValueSlice } from '@cam/redux/slices';

export const {
  actions: { set: setInviteCompany },
  reducer: inviteCompanyReducer,
} = createBooleanSlice(`${reducerName}/inviteCompany`, false);

export const inviteEmployee =
  (data: InvitationRequestResource): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    const { emails, role, job } = data;
    try {
      dispatch(setInviteCompany(true));
      const companyId = getSelectedCompanyId(getState());
      await Promise.all(
        emails.map(email => {
          return api.Employees.inviteEmployee(companyId, email, role, job);
        })
      );

      notifications().employees.inviteEmployee.success();
      navigate(Routes.EMPLOYEES.BASE);
      dispatch(fetchEmployees());
    } catch (error) {
      notifications().employees.inviteEmployee.failure(error);
    } finally {
      dispatch(setInviteCompany(false));
    }
  };

export const {
  actions: { set: setInvitationError },
  reducer: invitationErrorReducer,
} = createValueSlice(reducerName + '/invitationError', {
  isLoading: false,
  error: null as string | null,
});

export const acceptInvitation =
  (invitationToken: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    const user = getUser(getState());
    try {
      dispatch(setInvitationError({ isLoading: true, error: null }));

      const invitation = await api.Employees.acceptInvitation(user.email || '', invitationToken);
      if (!!invitation) {
        const { companyId, userEmail, inviteId } = invitation;
        const employee = await api.Employees.fetchEmployeeDetail(userEmail, companyId);

        if (!!employee) {
          await api.Employees.removeEmployee(userEmail, companyId);
          await api.Invite.removeInvitation(inviteId);
          await api.Employees.createEmployee(
            {
              userId: user.uid,
              email: user.email,
              displayName: user.displayName,
              companyId,
              role: employee.role,
              status: EmployeeStatus.ACTIVE,
              job: employee.job,
            },
            generatePermissionsMatrix(employee.role)
          );
          dispatch(fetchCompaniesList(user.uid, companyId));
          dispatch(setInvitationError({ isLoading: false, error: null }));
        } else {
          setInvitationError({
            isLoading: false,
            error: INVITATION_ERROR_CODES.EMAIL_NOT_MATCHED,
          });
        }
      } else {
        setInvitationError({
          isLoading: false,
          error: INVITATION_ERROR_CODES.TOKEN_NOT_FOUND,
        });
      }
    } catch (e) {
      dispatch(
        setInvitationError({
          isLoading: false,
          error: INVITATION_ERROR_CODES.FAILED,
        })
      );
    }
  };

export const thunks = {
  inviteEmployee,
  acceptInvitation,
};

const reducer = combineReducers({
  inviteCompany: inviteCompanyReducer,
  inviteCompanyError: invitationErrorReducer,
});

export default reducer;
