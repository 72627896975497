import { message } from 'antd'; // eslint-disable-line no-restricted-imports
import { useRef, useEffect, memo } from 'react';

export const useMessageWrapper = () => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref?.current) {
      message.config({ getContainer: () => ref.current || document.body });
    }
  }, [ref]);

  return ref;
};

// eslint-disable-next-line react/display-name
export const MessageWrapper: React.FC = memo(() => {
  const ref = useMessageWrapper();
  return <section aria-live="assertive" aria-atomic="true" ref={ref} />;
});
