import { useLocation, navigate } from '@reach/router';
import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingScreen } from '@cam/app/src/components/LoadingScreen';
import { Routes } from '@cam/app/src/constants/routes';
import { URL_PARAMS } from '@cam/app/src/constants/urlParams';
import { logout } from '@cam/app/src/features/oauth/utils';
import { getUser } from '@cam/app/src/redux/Auth/selectors';
import { thunks as companyThunks } from '@cam/app/src/redux/Company';
import { acceptInvitation } from '@cam/app/src/redux/Employees/invites';
import { getAcceptInvitationError } from '@cam/app/src/redux/Employees/selectors';

export enum INVITATION_ERROR_CODES {
  EMAIL_NOT_MATCHED = 'server.error.emailNotMatchedInInvitation',
  TOKEN_NOT_FOUND = 'server.error.invitationTokenNotFound',
  FAILED = 'server.error.invitationFailed',
}

const getErrorTranslations = (t: TFunction): Record<INVITATION_ERROR_CODES, string> => ({
  [INVITATION_ERROR_CODES.EMAIL_NOT_MATCHED]: t('common:invitation.error.reason.email'),
  [INVITATION_ERROR_CODES.FAILED]: t('common:invitation.error.reason.generic'),
  [INVITATION_ERROR_CODES.TOKEN_NOT_FOUND]: t('common:invitation.error.reason.token'),
});

export const Invitation: React.FC = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const invitationToken = new URLSearchParams(search).get(URL_PARAMS.INVITATION_TOKEN);
  const initialCompanyId = new URLSearchParams(search).get(URL_PARAMS.COMPANY_ID);
  const { email, uid } = useSelector(getUser);

  const error = useSelector(getAcceptInvitationError);

  const handleGoDashboard = () => {
    dispatch(companyThunks.fetchCompaniesList(uid, initialCompanyId));
    navigate(Routes.DASHBOARD.BASE);
  };

  useEffect(() => {
    if (invitationToken) {
      dispatch(acceptInvitation(invitationToken));
    } else {
      navigate(Routes.DASHBOARD.BASE);
    }
  }, [dispatch, invitationToken]);

  if (!error) {
    return <LoadingScreen isLoading />;
  }

  const errorTranslations = getErrorTranslations(t);
  const errorMessage = (
    <Trans /* i18next-extract-disable-line */
      t={t}
      i18nKey={errorTranslations[error] || errorTranslations[INVITATION_ERROR_CODES.FAILED]}
    />
  );

  if (error === INVITATION_ERROR_CODES.EMAIL_NOT_MATCHED) {
    return (
      <LoadingScreen
        title={errorMessage}
        subtitle={t('common:invitation.error.reason.emailSubtitle', { email })}
        primaryButton={{ text: t('common:invitation.error.button'), onClick: handleGoDashboard }}
        secondaryButton={{ text: t('common:userMenu.logout'), onClick: logout }}
      />
    );
  }

  return (
    <LoadingScreen
      title={errorMessage}
      primaryButton={{ text: t('common:invitation.error.buttonLogout'), onClick: logout }}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default Invitation;
