// copy reach router Link functionality
export { resolve } from '@reach/router/es/lib/utils';
export const shouldNavigate = (event: React.MouseEvent) =>
  !event.defaultPrevented &&
  event.button === 0 &&
  !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const safeSerialize = (obj: unknown) => {
  try {
    return JSON.stringify(obj);
  } catch {
    return obj;
  }
};
