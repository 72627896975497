import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { difference, union, xor as toggle } from 'lodash';
import { Key } from 'react';

export type Rows = {
  expandedRows: Key[];
  selectedRows: string[] | 'All';
  visibleRows: string[];
};

export type RowType = {
  ids: Key[] | 'All';
};

export const createRows = (
  name = '',
  initialState: Rows = { expandedRows: [], selectedRows: [], visibleRows: [] }
) => {
  return createSlice({
    name: `${name}/rows`,
    initialState,
    reducers: {
      setVisibleRows: {
        reducer: (state, { payload }: PayloadAction<{ id: string }[]>) => {
          state.visibleRows = payload.map(({ id }) => id);
        },
        prepare: (payload: { id: string }[]) => ({
          payload,
        }),
      },
      setSelectedRows: {
        reducer: (state, { payload }: PayloadAction<React.Key[] | 'All', string>) => {
          if (state.selectedRows === 'All' || payload === 'All') {
            state.selectedRows = payload as 'All';
          } else {
            const notVisibleSelection =
              state.visibleRows.length === 0
                ? []
                : difference(state.selectedRows, state.visibleRows);
            state.selectedRows = union(notVisibleSelection, payload as string[]);
          }
        },
        prepare: (payload: React.Key[] | 'All') => ({
          payload,
        }),
      },
      resetSelectedRows: {
        reducer: state => {
          state.selectedRows = [];
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
      setExpandedRows: {
        reducer: (state, { payload }: PayloadAction<readonly Key[]>) => {
          const notVisibleExpanded =
            state.expandedRows.length === 0
              ? []
              : difference(state.expandedRows, state.visibleRows);
          state.expandedRows = union(notVisibleExpanded, payload);
        },
        prepare: (payload: readonly Key[]) => ({
          payload,
        }),
      },
      toggleExpandedRow: {
        reducer: (state, { payload }: PayloadAction<Key>) => {
          state.expandedRows = toggle(state.expandedRows, [payload]);
        },
        prepare: (payload: Key) => ({
          payload,
        }),
      },
      expandAllRows: {
        reducer: state => {
          state.expandedRows = state.visibleRows;
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
      collapseAllRows: {
        reducer: state => {
          state.expandedRows = [];
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
      reset: {
        reducer: state => {
          state.selectedRows = [];
          state.expandedRows = [];
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
    },
  });
};
