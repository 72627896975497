import { TFunction } from 'i18next';

import { createMessage } from '@cam/app/src/features/notifications/defaults';

export const messages = (t: TFunction) => ({
  activateEmployee: createMessage({
    success: t('employees:messages.employee.activate.success') as string,
    failure: t('employees:messages.employee.activate.failure') as string,
  }),
  suspendEmployee: createMessage({
    success: t('employees:messages.employee.suspend.success') as string,
    failure: t('employees:messages.employee.suspend.failure') as string,
  }),
  removeEmployee: createMessage({
    success: t('employees:messages.delete.success') as string,
    failure: t('employees:messages.delete.failure') as string,
  }),
  removeInvitation: createMessage({
    success: t('employees:messages.employee.removeInvite.success') as string,
    failure: t('employees:messages.employee.removeInvite.failure') as string,
  }),
  inviteEmployee: createMessage({
    success: t('employees:messages.invite.success') as string,
    failure: t('employees:messages.invite.failure') as string,
  }),
});
