import { DateType } from '@date-io/type';
import { TFunction } from 'i18next';
import { findKey } from 'lodash';

import { DateUtil } from '@cam/app/src/utils/date';

export enum TimePeriod {
  '7days' = '7days',
  '30days' = '30days',
  '60days' = '60days',
  '90days' = '90days',
  'custom' = 'custom',
  'current' = 'current',
}

export type TimePeriodOptions = Exclude<TimePeriod, 'custom' | 'current'>;

export type DateRange = [Date, Date];
export type DateIORange = [DateType, DateType];

export const convertDateRangeToDayjs = ([from, to]: DateRange): DateIORange => [
  DateUtil.date(from),
  DateUtil.date(to),
];

export const getTimePeriodTranslations = (
  t: TFunction,
  past?: boolean
): Record<Exclude<TimePeriod, '60days'>, string> => ({
  '7days': past ? t('common:timePeriod.pastWeek') : t('common:timePeriod.nextWeek'),
  '30days': past ? t('common:timePeriod.pastMonth') : t('common:timePeriod.nextMonth'),
  '90days': past ? t('common:timePeriod.pastThreeMonths') : t('common:timePeriod.nextThreeMonths'),
  custom: t('common:timePeriod.custom'),
  current: t('common:timePeriod.current'),
});

const periodDaysCount: Record<TimePeriodOptions, number> = {
  '7days': 7,
  '30days': 30,
  '60days': 60,
  '90days': 90,
};

export const getPastRange = (
  startDate: DateType | ((endDate: DateType) => DateType)
): DateRange => {
  const to = DateUtil.endOfDay(DateUtil.date());
  const from = DateUtil.startOfDay(typeof startDate === 'function' ? startDate(to) : startDate);
  return [DateUtil.toJsDate(from), DateUtil.toJsDate(to)];
};

export const getFutureRange = (
  endDate: DateType | ((startDate: DateType) => DateType)
): DateRange => {
  const from = DateUtil.startOfDay(DateUtil.date());
  const to = DateUtil.endOfDay(typeof endDate === 'function' ? endDate(from) : endDate);
  return [DateUtil.toJsDate(from), DateUtil.toJsDate(to)];
};

export const getRangeFromPeriod = (option: TimePeriodOptions): DateRange => {
  return getPastRange(endDate => DateUtil.addDays(endDate, -periodDaysCount[option]));
};

export const getPeriodFromRange = ([from, to]: DateRange): TimePeriod => {
  const diff = DateUtil.getDiff(DateUtil.date(to), DateUtil.date(from), 'days');
  const key = findKey(periodDaysCount, period => period === diff) as TimePeriodOptions | undefined;
  return key || TimePeriod.custom;
};

export const getFutureRangeFromPeriod = (option: TimePeriodOptions): DateRange => {
  return getFutureRange(startDate => DateUtil.addDays(startDate, periodDaysCount[option]));
};

export const getRangeFilterTranslations = (t: TFunction): Record<TimePeriodOptions, string> => ({
  [TimePeriod['7days']]: t('common:table.filters.range', {
    count: periodDaysCount['7days'],
  }),
  [TimePeriod['30days']]: t('common:table.filters.range', {
    count: periodDaysCount['30days'],
  }),
  [TimePeriod['60days']]: t('common:table.filters.range', {
    count: periodDaysCount['60days'],
  }),
  [TimePeriod['90days']]: t('common:table.filters.range', {
    count: periodDaysCount['90days'],
  }),
});

export const getRangeMutedFilterTranslations = (
  t: TFunction
): Record<TimePeriodOptions, string> => ({
  [TimePeriod['7days']]: t('common:table.filters.muted.range', {
    count: periodDaysCount['7days'],
  }),
  [TimePeriod['30days']]: t('common:table.filters.muted.range', {
    count: periodDaysCount['30days'],
  }),
  [TimePeriod['60days']]: t('common:table.filters.muted.range', {
    count: periodDaysCount['60days'],
  }),
  [TimePeriod['90days']]: t('common:table.filters.muted.range', {
    count: periodDaysCount['90days'],
  }),
});

export const serializeDateRange = (type: 'future' | 'past', option?: TimePeriodOptions) => {
  if (!option) {
    return {};
  }
  const [from, to] =
    type === 'past' ? getRangeFromPeriod(option) : getFutureRangeFromPeriod(option);
  return { from: from.toISOString(), to: to.toISOString() };
};
