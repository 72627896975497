import { combineReducers } from '@reduxjs/toolkit';
import { doc } from 'firebase/firestore';

import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { customersAdapter } from '@cam/app/src/redux/Customers/adapters';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { AppThunk } from '@cam/app/src/redux/store';
import { db } from '@cam/firebase';
import { Customer } from '@cam/firebase/resource/Event';
import { createTableActions } from '@cam/redux/table';

const reducerName = 'Customers';

export const {
  actions: { dataActions, rowsActions, paginationActions, filterActions, sorterActions },
  reducers: { dataReducer, rowsReducer, paginationReducer, filterReducer, sorterReducer },
} = createTableActions({ reducerName, adapter: customersAdapter });

export const fetchCustomers =
  (): AppThunk =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(dataActions.getDataRequest());
      const companyId = getSelectedCompanyId(getState());
      const data = await api.Customers.fetchCustomers(companyId);
      dispatch(dataActions.getDataSuccess(data));
    } catch {
      dispatch(dataActions.getDataFailure());
    }
  };

export const createCustomer =
  (customer: Omit<Customer, 'customerId'>): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      const companyId = getSelectedCompanyId(getState());
      const { id: customerId } = doc(db.Customer.customer);
      await api.Customers.createCustomer({ ...customer, customerId, companyId });
      notifications().customers.createCustomer.success();
      dispatch(fetchCustomers());
    } catch (error) {
      console.log('🚀 ~ file: index.ts ~ line 42 ~ error', error);
      notifications().customers.createCustomer.failure();
    } finally {
      dispatch(destroyModal());
    }
  };

export const reset = (): AppThunk => async dispatch => {
  dispatch(paginationActions.reset());
  dispatch(dataActions.reset());
  dispatch(rowsActions.reset());
  dispatch(sorterActions.reset());
  dispatch(filterActions.reset());
};

const combinedReducer = combineReducers({
  data: dataReducer,
  pagination: paginationReducer,
  filter: filterReducer,
  rows: rowsReducer,
  sorter: sorterReducer,
});

export default combinedReducer;
