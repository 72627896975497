import { modalMap } from '@cam/app/src/features/modals/modalMap';
import { ModalType } from '@cam/app/src/redux/Modals/types';
import { ExtractProps } from '@cam/app/src/utils/typescript';
import { createModalsSlice } from '@cam/redux/slices/modal';

type ModalConfig<T extends ModalType = ModalType> = {
  type: T;
  props: Omit<ExtractProps<typeof modalMap[T]>, 'modalType'>;
};

export const { actions, reducer } = createModalsSlice<ModalConfig>();

export const { destroyModal, startConfirmLoading, setIsConfirmationLoading } = actions;

// generic action creator, no idea how to do it inside a slice
export const showModal = <T extends ModalType>(config: ModalConfig<T>) => actions.showModal(config);

export { ModalType };

export default reducer;
