import {
  collection,
  getFirestore,
  QueryDocumentSnapshot,
  WithFieldValue,
} from 'firebase/firestore';

import { UserResource } from '@cam/firebase/resource/Auth';
import {
  BalanceResource,
  CashRegisterListResource,
  Category,
} from '@cam/firebase/resource/CashRegister';
import { AccessResource, CompanyResource, SalaryResource } from '@cam/firebase/resource/Company';
import { EmployeeResource } from '@cam/firebase/resource/Employee';
import { CustomerResource, EventResource } from '@cam/firebase/resource/Event';
import { InvitationEmailResource, InvitationResource } from '@cam/firebase/resource/Invitation';
import { PaymentResource } from '@cam/firebase/resource/Payment';

const firestore = getFirestore();

const converter = <T>() => ({
  toFirestore: (data: WithFieldValue<T>) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

const dataPoint = <T>(collectionPath: string) =>
  collection(firestore, collectionPath).withConverter(converter<T>());

export const db = {
  User: {
    user: dataPoint<UserResource>('user'),
  },
  Customer: {
    customer: dataPoint<CustomerResource>('customer'),
  },
  Company: {
    employee: dataPoint<EmployeeResource>('employee'),
    salary: dataPoint<SalaryResource>('salary'),
    company: dataPoint<CompanyResource>('company'),
    access: dataPoint<AccessResource>('access'),
  },
  Transaction: {
    transaction: dataPoint<PaymentResource>('transaction'),
  },
  CashRegister: {
    cashRegister: (companyId: string) =>
      dataPoint<CashRegisterListResource>(`cashRegister/${companyId}/cashRegisterRecords`),
    categories: (companyId: string) => dataPoint<Category>(`cashRegister/${companyId}/categories`),
    balance: (companyId: string) => dataPoint<BalanceResource>(`cashRegister/${companyId}/balance`),
  },
  Event: {
    events: dataPoint<EventResource>('event'),
  },
  Invite: {
    mail: dataPoint<InvitationEmailResource>('mail'),
    invitations: dataPoint<InvitationResource>('invitation'),
  },
};
