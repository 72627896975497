import { createColors, createPalette, getBaseTheme } from '@cam/theme/helpers';
import { BasePalette, Theme } from '@cam/theme/types';

const base: BasePalette = {
  blue: '#2f54eb',
  green: '#52c41a',
  red: '#d93511',
  orange: '#fa8c16',
  gold: '#faad14',
};
const neutralBase = '#353b48';
const extraDarkBase = '#353b48';
const processingColor = '#1890ff';

const { palette, neutralPalette } = createPalette(base, neutralBase, extraDarkBase);
const colors = createColors({ palette, neutralPalette, info: processingColor });
const charts = {
  status: {
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    neutral: colors.neutral.light.secondary,
  },
};

export const theme: Theme = {
  ...getBaseTheme(),
  colors: { ...colors, charts },
  buttonRadius: 52,
  fontFamily: 'Open Sans, sans-serif',
};
