import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginationProps } from 'antd/es/pagination';

export const defaults = {
  page: 1,
  size: 25,
  options: [25, 50, 100],
};

const initialState = {
  pageSize: defaults.size,
  current: defaults.page,
  total: 0,
};

export type Pagination = typeof initialState;
export type PaginationType = Pick<PaginationProps, 'pageSize' | 'current'>;

export const createPagination = (name: string) => {
  return createSlice({
    name: `${name}/pagination`,
    initialState,
    reducers: {
      setTotal: {
        reducer: (state, { payload }: PayloadAction<number>) => {
          state.total = payload;
        },
        prepare: (payload: number) => ({
          payload,
        }),
      },
      setPagination: {
        reducer: (state, { payload }: PayloadAction<PaginationType>) => {
          state.pageSize = payload.pageSize || initialState.pageSize;
          state.current = payload.current || initialState.current;
        },
        prepare: (payload: PaginationType) => ({
          payload,
        }),
      },
      reset: {
        reducer: state => {
          state.current = initialState.current;
          state.total = initialState.total;
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
    },
  });
};
