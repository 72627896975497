import { signOut } from 'firebase/auth';

import { auth } from '@cam/firebase/clientApp';

export let isLoggingOut = false;

export const logout = async () => {
  isLoggingOut = true;
  await signOut(auth);
  isLoggingOut = false;
  //   TODO @psenicka: what after logout?
};

export const refreshToken = async () => {
  auth.currentUser?.getIdToken(true);
};
