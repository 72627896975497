import { Timestamp } from 'firebase/firestore';

import { DateType } from '@cam/app/src/utils/date';
import { Currency, SortDirection } from '@cam/firebase/resource/Common';

export interface Amount {
  amount: number;
  currency: Currency;
}

export interface CashRegisterList {
  cashRegisterRecordId: string;
  date: DateType;
  categoryKey: string;
  description?: string;
  amount: Amount;
  dph: Amount;
  amountWithoutDph: Amount;
  cashRegisterBalance: Balance;
  cashRegisterCorrection: Balance;
}

export interface CashRegisterListResource
  extends Omit<CashRegisterList, 'date' | 'cashRegisterBalance' | 'cashRegisterCorrection'> {
  date: Timestamp;
  cashRegisterBalance: BalanceResource;
  cashRegisterCorrection: BalanceResource;
}

export interface Balance {
  date: DateType;
  amount: number;
  currency: Currency;
}

export interface BalanceResource extends Omit<Balance, 'date'> {
  date: Timestamp;
}

export type Category = { key: string; name: string };
export enum DefaultCategories {
  EVENT = 'EVENT',
  OTHER = 'OTHER',
}

export interface CashRegisterListFilterResource {
  categories?: string[];
  dph?: boolean;
}

interface FilterResourceCashRegisterListFilterResource {
  filterAnd?: Array<CashRegisterListFilterResource>;
}

interface SortedPageCashRegisterListSorterResource {
  page: number;
  size: number;
  sorting: Array<SortRequestEventListSorted>;
}

export enum CashRegisterListSorted {
  DATE = 'DATE',
  AMOUNT = 'AMOUNT',
  DPH = 'DPH',
  AMOUNT_WITHOUT_DPH = 'DPH',
}

interface SortRequestEventListSorted {
  direction: SortDirection;
  field: CashRegisterListSorted;
}

export interface PagedCashRegisterListFilterResourceSorted {
  filter: FilterResourceCashRegisterListFilterResource;
  sortedPage: SortedPageCashRegisterListSorterResource;
}
