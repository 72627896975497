import { createSelector } from '@reduxjs/toolkit';

import {
  companyAdapter,
  accessRigthsAdapter,
  companyInfoAdapter,
} from '@cam/app/src/redux/Company/adapters';
import { RootState } from '@cam/app/src/redux/reducer';

export const { selectAll, selectTotal, selectById, selectEntities, selectIds } =
  companyAdapter.getSelectors((state: RootState) => state.Company.companies.data);

export const accessSelectors = accessRigthsAdapter.getSelectors(
  (state: RootState) => state.Company.accessRights.data
);

export const companyInfoSelectors = companyInfoAdapter.getSelectors(
  (state: RootState) => state.Company.companyInfo.data
);

export const getIsInitialized = (state: RootState) => state.Company.companies.isInitialized;
export const getIsFetchingCompanies = (state: RootState) => state.Company.companies.isLoading;
export const getIsFetchingCompaniesError = (state: RootState) => state.Company.companies.hasError;
export const getUpdatingCompanyIds = (state: RootState) => state.Company.companies.updatingIds;

export const getSelectedCompanyId = (state: RootState) => state.Company.selectedCompanyId;

export const getCompanyUpdatingIds = (state: RootState) => state.Company.companyInfo.updatingIds;

export const getIsUpdatingCompanyProfile = createSelector(
  [getUpdatingCompanyIds, getCompanyUpdatingIds, (_, companyId: string) => companyId],
  (updatingList, updatingInfo, companyId) => [...updatingList, ...updatingInfo].includes(companyId)
);

export const getIsUpdatingAccount = createSelector(
  [getUpdatingCompanyIds, getCompanyUpdatingIds, (_, companyId: string) => companyId],
  (updatingMulti, updatingSingle, companyId) =>
    [...updatingMulti, ...updatingSingle].includes(companyId)
);

export const getSelectedCompany = createSelector(
  [selectEntities, getSelectedCompanyId],
  (companies, companyId) => companies[companyId]
);
