import { createEntityAdapter } from '@reduxjs/toolkit';

import { Access, CompanyList, CompanyResource } from '@cam/firebase/resource/Company';

export const companyAdapter = createEntityAdapter<CompanyList>({
  selectId: company => company.companyId,
});

export const accessRigthsAdapter = createEntityAdapter<Access>({
  selectId: access => access.scope,
});

export const companyInfoAdapter = createEntityAdapter<CompanyResource>({
  selectId: company => company.companyId,
});
