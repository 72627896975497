import { createSelector, EntityAdapter } from '@reduxjs/toolkit';
import { SortOrder, SorterResult } from 'antd/es/table/interface';
import { difference } from 'lodash';

import { TypedSorterResult } from '@cam/app/src/utils/antd';
import { StringKeys } from '@cam/app/src/utils/typescript';
import { EntitySliceState } from '@cam/redux/slices/entity';
import { serializeSortedPage, serializeFilters, serializeFilterSorter } from '@cam/redux/table/api';
import { FilterType, FilterState } from '@cam/redux/table/filter';
import { Pagination } from '@cam/redux/table/pagination';
import { Rows } from '@cam/redux/table/rows';
import { getEntitiesByIds } from '@cam/redux/utils';

// copy BE enum
enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

const sortDirMap: Record<NonNullable<SortOrder>, SortDirection> = {
  ascend: SortDirection.ASC,
  descend: SortDirection.DESC,
};

export type SerializedSorter<SortField> = {
  sortDir: SortDirection;
  sortedBy?: SortField;
};

type SortMap<SortIn, SortOut> = Partial<Record<StringKeys<SortIn>, SortOut>>;

export function createSorterSelectors<SortIn, SortOut, State>(
  sorterSelector: (state: State) => SorterResult<SortIn>,
  sortMap: SortMap<SortIn, SortOut> = {}
) {
  const getSorter = (state: State) => sorterSelector(state) as TypedSorterResult<SortIn>;
  const getSorterSerialized = createSelector([getSorter], sorter => {
    const serializedSorter: SerializedSorter<SortOut> = {
      sortDir: sorter.order ? sortDirMap[sorter.order] : SortDirection.ASC,
      sortedBy: sortMap[sorter.column?.dataIndex],
    };
    return serializedSorter;
  });

  const isActive = createSelector([getSorter], sorter => !!(sorter.column || sorter.order));

  return {
    getSorter,
    getSorterSerialized,
    isActive,
  };
}

/* TS hacks to infer return type of a generic function */
/* eslint-disable @typescript-eslint/no-explicit-any */
class SorterSelectorsWrapper<A, B, C> {
  wrapper = () => createSorterSelectors<A, B, C>(null as any, null as any);
}
export type SorterSelectors<A = any, B = any, C = any> = ReturnType<
  SorterSelectorsWrapper<A, B, C>['wrapper']
>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export type SerializedPagination = {
  page: number;
  size: number;
};

export function createPaginationSelectors<State>(paginationSelector: (state: State) => Pagination) {
  const getPagination = paginationSelector;
  const getPaginationSerialized = createSelector([getPagination], pagination => {
    const serializedPagination: SerializedPagination = {
      page: pagination.current,
      size: pagination.pageSize,
    };
    return serializedPagination;
  });

  return {
    getPagination,
    getPaginationSerialized,
  };
}

type FilterSerializer<FilterIn, FilterOut> = (filter: FilterType<FilterIn>) => FilterOut;

export function createFilterSelectors<FilterIn, FilterOut, State>(
  filterSelector: (state: State) => FilterState<FilterIn>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serializer: FilterSerializer<FilterIn, FilterOut> = () => ({} as any)
) {
  const getFilter = createSelector([filterSelector], filterState => filterState.filter);
  const getFilteredCount = createSelector(
    [filterSelector],
    filterState => filterState.filteredCount
  );
  const getFilterWithoutVisibleColumns = createSelector([getFilter], filter => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { visibleColumns, ...filterForResource } = filter;
    return filterForResource;
  });
  const getFilterSerialized = createSelector([getFilter], serializer);

  const isActive = createSelector([getFilterWithoutVisibleColumns], filter => {
    return Object.values(filter).some(item => item && (item as (string | number)[]).length > 0);
  });

  return {
    getFilter,
    getFilteredCount,
    getFilterWithoutVisibleColumns,
    getFilterSerialized,
    isActive,
  };
}

/* TS hacks to infer return type of a generic function */
/* eslint-disable @typescript-eslint/no-explicit-any */
class FilterSelectorsWrapper<A, B, C> {
  wrapper = () => createFilterSelectors<A, B, C>(null as any, null as any);
}
export type FilterSelectors<A = any, B = any, C = any> = ReturnType<
  FilterSelectorsWrapper<A, B, C>['wrapper']
>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export function createRowsSelector<State>(rowsSelector: (state: State) => Rows) {
  const getVisibleRows = createSelector([rowsSelector], rows => rows.visibleRows);
  const getSelectedRows = createSelector([rowsSelector], rows => rows.selectedRows);
  const getExpandedRows = createSelector([rowsSelector], rows => rows.expandedRows as string[]);

  const getAreAllRowsExpanded = createSelector(
    [getVisibleRows, getExpandedRows],
    (visibleRows, expandedRows) => difference(visibleRows, expandedRows).length === 0
  );

  const getIsRowExpanded = createSelector(
    [getExpandedRows, (_: State, entityId: string) => entityId],
    (expandedRows, entityId) => expandedRows.includes(entityId)
  );

  return {
    getVisibleRows,
    getSelectedRows,
    getExpandedRows,
    getAreAllRowsExpanded,
    getIsRowExpanded,
  };
}

type TableState<DataEntity> = {
  data: EntitySliceState<DataEntity>;
  filter: FilterState<DataEntity>;
  sorter: SorterResult<DataEntity>;
  pagination: Pagination;
  rows: Rows;
};

type TableSelectors<DataEntity, FilterOut, SortOut, State, SerializedDataEntity = DataEntity> = {
  selector: (state: State) => TableState<DataEntity>;
  serializeFilter?: FilterSerializer<DataEntity, FilterOut>;
  sortMap?: SortMap<DataEntity, SortOut>;
  adapter: EntityAdapter<DataEntity>;
  serializeData?: (data: DataEntity) => SerializedDataEntity;
};

export const createEntitySelectors = <DataEntity, State>(
  selector: (state: State) => EntitySliceState<DataEntity>,
  adapter: EntityAdapter<DataEntity>
) => {
  const dataSelectors = adapter.getSelectors((state: State) => selector(state).data);

  return {
    rawDataSelectors: selector,
    ...dataSelectors,
  };
};

export const createTableSelectors = <
  DataEntity,
  FilterOut,
  SortOut,
  State,
  SerializedDataEntity = DataEntity
>({
  selector,
  adapter,
  serializeFilter,
  sortMap,
  serializeData = x => x as unknown as SerializedDataEntity,
}: TableSelectors<DataEntity, FilterOut, SortOut, State, SerializedDataEntity>) => {
  const { rawDataSelectors, ...dataSelectors } = createEntitySelectors(
    (state: State) => selector(state).data,
    adapter
  );

  const filterSelectors = createFilterSelectors(
    (state: State) => selector(state).filter,
    serializeFilter
  );
  const paginationSelectors = createPaginationSelectors(
    (state: State) => selector(state).pagination
  );
  const rowsSelectors = createRowsSelector((state: State) => selector(state).rows);
  const sorterSelectors = createSorterSelectors((state: State) => selector(state).sorter, sortMap);

  const getVisibleData = createSelector(
    [rowsSelectors.getVisibleRows, dataSelectors.selectEntities],
    getEntitiesByIds
  );

  const getSerializedData = createSelector([getVisibleData], data => data.map(serializeData));

  const getSelectedData = createSelector(
    [rowsSelectors.getSelectedRows, dataSelectors.selectEntities, dataSelectors.selectAll],
    (selectedRows, entitiesById, entities) =>
      selectedRows === 'All' ? entities : selectedRows.map(id => entitiesById[id])
  );

  const getSelectedCount = createSelector(
    [paginationSelectors.getPagination, rowsSelectors.getSelectedRows],
    (pagination, selectedRows) => (selectedRows === 'All' ? pagination.total : selectedRows.length)
  );

  const getSortedPageSerialized = createSelector(
    [paginationSelectors.getPaginationSerialized, sorterSelectors.getSorterSerialized],
    serializeSortedPage
  );

  const getFilterSerialized = createSelector(
    [
      filterSelectors.getFilterSerialized,
      (_: State, additionalFilter?: FilterOut) => additionalFilter as FilterOut,
    ],
    serializeFilters
  );

  const getFilterSerializedFn = <FilterAdditional>(
    filterSelector: (state: State) => FilterAdditional,
    serialize: (value: FilterAdditional) => FilterOut
  ) =>
    createSelector(
      [
        filterSelectors.getFilterSerialized,
        (_: State, additionalFilter?: FilterOut) => additionalFilter as FilterOut,
        filterSelector,
      ],
      (filter, add, value) => serializeFilters(filter, add, serialize(value))
    );

  const getFilterSorterSerialized = createSelector(
    [getFilterSerialized, getSortedPageSerialized],
    serializeFilterSorter
  );

  const getFilterSorterSerializedFn = (
    filterSerializedSelector: ReturnType<typeof getFilterSerializedFn>
  ) => createSelector([filterSerializedSelector, getSortedPageSerialized], serializeFilterSorter);

  /**
   * @deprecated this API is deprecated on the backend, should use separate sorter/filter
   */
  const getLegacyFilterSerialized = createSelector(
    [
      filterSelectors.getFilterSerialized,
      sorterSelectors.getSorterSerialized,
      paginationSelectors.getPaginationSerialized,
    ],
    (filter, sorter, pagination) => ({ ...filter, ...sorter, ...pagination } as FilterOut)
  );

  const getTableState = createSelector(
    [
      filterSelectors.getFilter,
      sorterSelectors.getSorter,
      paginationSelectors.getPagination,
      rowsSelectors.getExpandedRows,
      rowsSelectors.getSelectedRows,
      rowsSelectors.getAreAllRowsExpanded,
      rawDataSelectors,
      getSerializedData,
      getSelectedCount,
      getSelectedData,
    ],
    (
      filter,
      sorter,
      pagination,
      expandedRows,
      selectedRows,
      areAllRowsExpanded,
      rawData,
      visibleData,
      selectedCount,
      selectedData
    ) => ({
      filter,
      sorter,
      pagination,
      expandedRows,
      selectedRows,
      selectedCount,
      areAllRowsExpanded,
      isLoading: rawData.isLoading,
      data: visibleData,
      selectedData,
    })
  );

  return {
    rawDataSelectors,
    dataSelectors,
    filterSelectors,
    paginationSelectors,
    rowsSelectors,
    sorterSelectors,
    getVisibleData: getSerializedData,
    getSelectedCount,
    getSelectedData,
    getSortedPageSerialized,
    getFilterSerialized,
    getFilterSerializedFn,
    getFilterSorterSerialized,
    getFilterSorterSerializedFn,
    getTableState,
    getLegacyFilterSerialized,
  };
};
