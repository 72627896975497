import { Dictionary, EntityAdapter } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getEntitiesByIds = <T>(ids: string[], entitiesById: Dictionary<T>) =>
  ids.map(id => entitiesById[id]).filter(Boolean) as T[];

// used for setVisibleRows if the entity does not have an explicit id
export const mapRowIds = <T>(entities: T[], adapter: EntityAdapter<T>) =>
  entities.map(entity => ({ id: adapter.selectId(entity) as string }));
