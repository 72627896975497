import { eventListAdapter } from '@cam/app/src/redux/Events/adapters';
import { reducerName } from '@cam/app/src/redux/Events/eventList';
import { RootState } from '@cam/app/src/redux/reducer';
import { AppThunk } from '@cam/app/src/redux/store';
import { DateUtil } from '@cam/app/src/utils/date';
import { SortDirection } from '@cam/firebase/resource/Common';
import { EventListSorted } from '@cam/firebase/resource/Event';
import { createEntitySlice } from '@cam/redux/slices';

export const { actions: eventWidgetActions, reducer: eventWidgetReducer } = createEntitySlice(
  `${reducerName}/companyInfo`,
  eventListAdapter
);

export const fetchEventWidget =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(eventWidgetActions.getDataRequest());
      const { data, countByFilter } = await api.Event.fetchEvents(
        companyId,
        {
          filter: {},
          sortedPage: {
            page: 1,
            size: 25,
            sorting: [{ field: EventListSorted.DATE, direction: SortDirection.ASC }],
          },
        },
        [DateUtil.date().toDate(), DateUtil.endOfMonth(DateUtil.date()).toDate()]
      );
      dispatch(eventWidgetActions.getDataSuccess(data, { override: true }));
    } catch (e) {
      console.log('errorek', e);
      dispatch(eventWidgetActions.getDataFailure());
    }
  };

export const eventWidgetSelectors = eventListAdapter.getSelectors(
  (state: RootState) => state.Events.eventWidget.data
);

export const getIsLoading = (state: RootState) => state.Events.eventWidget.isLoading;
