import { navigate } from '@reach/router';
import { combineReducers } from '@reduxjs/toolkit';
import { doc } from 'firebase/firestore';

import { Routes } from '@cam/app/src/constants/routes';
import { getUser } from '@cam/app/src/redux/Auth/selectors';
import { AppThunk } from '@cam/app/src/redux/store';
import { generatePermissionsMatrix } from '@cam/app/src/utils/permissionsMatrix';
import { db } from '@cam/firebase';
import { CompanyCredentialsResource, RoleResource } from '@cam/firebase/resource/Company';
import { EmployeeStatus } from '@cam/firebase/resource/Employee';
import { createResourceSlice } from '@cam/redux/slices';

const reducerName = 'Signup';

const { actions: companyActions, reducer: companyReducer } = createResourceSlice<void>({
  name: `${reducerName}/company`,
});

export const createCompany =
  (credentials: CompanyCredentialsResource): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    const user = getUser(getState());
    if (!!user) {
      try {
        dispatch(companyActions.request());
        const { id: companyId } = doc(db.Company.company);
        await api.Company.createCompany(companyId, credentials);
        await api.Employees.createEmployee(
          {
            userId: user.uid,
            email: user.email,
            displayName: user.displayName,
            companyId,
            role: RoleResource.ADMIN,
            status: EmployeeStatus.ACTIVE,
          },
          generatePermissionsMatrix(RoleResource.ADMIN)
        );
        dispatch(companyActions.success());

        navigate(Routes.DASHBOARD.BASE);
        window.location.reload();
      } catch (error) {
        dispatch(companyActions.failure());
        notifications().defaults.failure();
      }
    } else {
      notifications().defaults.failure();
    }
  };

export const thunks = { createCompany };

const reducer = combineReducers({
  company: companyReducer,
});

export default reducer;
