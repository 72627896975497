import { combineReducers } from '@reduxjs/toolkit';

import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { employeesAdapter, reducerName } from '@cam/app/src/redux/Employees/adapters';
import { employeeDetailReducer } from '@cam/app/src/redux/Employees/detail';
import invitesReducer from '@cam/app/src/redux/Employees/invites';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { AppThunk } from '@cam/app/src/redux/store';
import { createTableActions } from '@cam/redux/table';

export const {
  actions: { dataActions, rowsActions, paginationActions, filterActions, sorterActions },
  reducers: { dataReducer, rowsReducer, paginationReducer, filterReducer, sorterReducer },
} = createTableActions({ reducerName, adapter: employeesAdapter });

export const fetchEmployees =
  (): AppThunk =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(dataActions.getDataRequest());
      const companyId = getSelectedCompanyId(getState());

      const data = await api.Employees.fetchEmployees(companyId);
      dispatch(dataActions.getDataSuccess(data, { override: true }));
    } catch {
      dispatch(dataActions.getDataFailure());
    }
  };

export const removeInvitation =
  (userId: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      dispatch(dataActions.updateDataRequest([userId]));
      const companyId = getSelectedCompanyId(getState());

      await api.Employees.removeInvitation(userId, companyId);

      dispatch(dataActions.removeEntities([userId]));
      dispatch(dataActions.updateDataSuccess({ entityIds: [userId] }));
      notifications().employees.removeInvitation.success();
    } catch (error) {
      dispatch(dataActions.updateDataFailure([userId]));
      notifications().employees.removeInvitation.failure(error);
    } finally {
      dispatch(destroyModal());
    }
  };

export const removeEmployee =
  (userId: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      dispatch(dataActions.updateDataRequest([userId]));
      const companyId = getSelectedCompanyId(getState());
      await api.Employees.removeEmployee(userId, companyId);
      dispatch(dataActions.removeEntities([userId]));
      dispatch(dataActions.updateDataSuccess({ entityIds: [userId] }));
      notifications().employees.removeEmployee.success();
    } catch (error) {
      dispatch(dataActions.updateDataFailure([userId]));
      notifications().employees.removeEmployee.failure(error);
    } finally {
      dispatch(destroyModal());
    }
  };

export const reset = (): AppThunk => async dispatch => {
  dispatch(paginationActions.reset());
  dispatch(dataActions.reset());
  dispatch(rowsActions.reset());
  dispatch(sorterActions.reset());
  dispatch(filterActions.reset());
};

export const thunks = {
  fetchEmployees,
  removeEmployee,
  removeInvitation,
};

const combinedReducer = combineReducers({
  data: dataReducer,
  pagination: paginationReducer,
  filter: filterReducer,
  rows: rowsReducer,
  sorter: sorterReducer,
  invites: invitesReducer,
  detail: employeeDetailReducer,
});

export default combinedReducer;
