import { LocationProvider } from '@reach/router';
import { EnhancedStore } from '@reduxjs/toolkit';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import { ErrorBoundary } from '@cam/app/src/components/ErrorBoundary';
import { useFormMessages } from '@cam/app/src/components/Form/messages';
import { MessageWrapper } from '@cam/app/src/components/MessageWrapper';
import { SecuredApp } from '@cam/app/src/features/oauth';
import { Lang } from '@cam/app/src/locales';
import { getAntdLocale } from '@cam/app/src/locales/vendor';
import { getPopupContainer } from '@cam/app/src/utils/antd';

export const AppProvider: React.FC<{ reduxStore: EnhancedStore }> = ({ reduxStore, children }) => {
  const { i18n } = useTranslation();
  const formMessages = useFormMessages();

  return (
    <LocationProvider>
      <Provider store={reduxStore}>
        <ConfigProvider
          locale={getAntdLocale(i18n.language as Lang)}
          getPopupContainer={getPopupContainer}
          form={{ validateMessages: formMessages }}
        >
          <MessageWrapper />
          {children}
        </ConfigProvider>
      </Provider>
    </LocationProvider>
  );
};

export const AuthAppProvider: React.FC<{
  app: React.ReactNode;
}> = ({ app }) => {
  return (
    <ErrorBoundary>
      <SecuredApp>{app}</SecuredApp>
    </ErrorBoundary>
  );
};
