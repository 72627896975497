import styled from 'styled-components';

import { logo } from '@cam/app/src/components/Logo';

const Wrapper = styled.section`
  background-color: ${props => props.theme.colors.neutral.dark.bg};
  min-height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 72px 32px;
`;

const Logo = styled.img`
  width: 360px;
  max-height: 200px;
  object-fit: contain;
  justify-self: flex-start;
`;

export const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Logo src={logo.color.main} alt="logo" />
      {children}
    </Wrapper>
  );
};
