import { Access, Permission, RoleResource, Scope } from '@cam/firebase/resource/Company';

export const generatePermissionsMatrix = (role: RoleResource): Access[] => {
  const readWrite = [Permission.READ, Permission.WRITE];
  const read = [Permission.READ];

  switch (role) {
    case RoleResource.ADMIN:
      return [
        {
          scope: Scope.COMPANY,
          permissions: readWrite,
        },
        {
          scope: Scope.EVENT,
          permissions: readWrite,
        },
        {
          scope: Scope.REPORT,
          permissions: readWrite,
        },
        {
          scope: Scope.EMPLOYEE,
          permissions: readWrite,
        },
        {
          scope: Scope.CUSTOMER,
          permissions: readWrite,
        },
        {
          scope: Scope.CASH_REGISTER,
          permissions: readWrite,
        },
        {
          scope: Scope.SALARY,
          permissions: readWrite,
        },
      ];
    case RoleResource.EDITOR:
      return [
        {
          scope: Scope.COMPANY,
          permissions: read,
        },
        {
          scope: Scope.EVENT,
          permissions: readWrite,
        },
        {
          scope: Scope.REPORT,
          permissions: [],
        },
        {
          scope: Scope.EMPLOYEE,
          permissions: read,
        },
        {
          scope: Scope.CUSTOMER,
          permissions: readWrite,
        },
        {
          scope: Scope.CASH_REGISTER,
          permissions: [],
        },
        {
          scope: Scope.SALARY,
          permissions: [],
        },
      ];
    case RoleResource.VIEWER:
      return [
        {
          scope: Scope.COMPANY,
          permissions: read,
        },
        {
          scope: Scope.EVENT,
          permissions: read,
        },
        {
          scope: Scope.REPORT,
          permissions: [],
        },
        {
          scope: Scope.EMPLOYEE,
          permissions: read,
        },
        {
          scope: Scope.CUSTOMER,
          permissions: read,
        },
        {
          scope: Scope.CASH_REGISTER,
          permissions: [],
        },
        {
          scope: Scope.SALARY,
          permissions: [],
        },
      ];
    default:
      return [
        {
          scope: Scope.COMPANY,
          permissions: [],
        },
        {
          scope: Scope.EVENT,
          permissions: [],
        },
        {
          scope: Scope.REPORT,
          permissions: [],
        },
        {
          scope: Scope.EMPLOYEE,
          permissions: [],
        },
        {
          scope: Scope.CUSTOMER,
          permissions: [],
        },
        {
          scope: Scope.CASH_REGISTER,
          permissions: [],
        },
        {
          scope: Scope.SALARY,
          permissions: [],
        },
      ];
  }
};
