import { Spin as AntdSpin, SpinProps } from 'antd';
import styled, { DefaultTheme } from 'styled-components';

type SpinColor = 'brand' | 'default';

const getSpinColor = (theme: DefaultTheme, color: SpinColor) => {
  if (color === 'brand') {
    return theme.colors.brand;
  }
  return theme.colors.neutral.light.primary;
};

const StyledSpin = styled(AntdSpin)<{ $color: SpinColor }>`
  && {
    .ant-spin-dot-item {
      background-color: ${props => getSpinColor(props.theme, props.$color)};
    }
  }
`;

export const Spin: React.FC<SpinProps & { color?: SpinColor }> = ({
  color = 'default',
  ...props
}) => <StyledSpin $color={color} {...props} />;
