import { TFunction } from 'i18next';

import { getDefaultCategoriesTranslations } from '@cam/app/src/features/cashRegister/categories/form/translation';
import { Category, DefaultCategories } from '@cam/firebase/resource/CashRegister';

export const getDefaultCategories = (t: TFunction) => {
  return Object.keys(DefaultCategories).map(
    key =>
      ({
        key,
        name: getDefaultCategoriesTranslations(t)[key],
      } as Category)
  );
};
