import { TFunction } from 'i18next';

import { createMessage } from '@cam/app/src/features/notifications/defaults';

export const messages = (t: TFunction) => ({
  createRecord: createMessage({
    success: t('cashRegister:messages.create.success') as string,
    failure: t('cashRegister:messages.create.failure') as string,
  }),
  updateRecord: createMessage({
    success: t('cashRegister:messages.update.success') as string,
    failure: t('cashRegister:messages.update.failure') as string,
  }),
  deleteRecord: createMessage({
    success: t('cashRegister:messages.delete.success') as string,
    failure: t('cashRegister:messages.delete.failure') as string,
  }),
  createCategory: createMessage({
    success: t('cashRegister:messages.category.create.success') as string,
    failure: t('cashRegister:messages.category.create.failure') as string,
  }),
  deleteCategory: createMessage({
    success: t('cashRegister:messages.category.delete.success') as string,
    failure: t('cashRegister:messages.category.delete.failure') as string,
  }),
});
