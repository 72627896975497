import { createEntityAdapter } from '@reduxjs/toolkit';

import { Employee, EmployeeDetailResource } from '@cam/firebase/resource/Employee';

export const reducerName = 'Employees';

export const employeesAdapter = createEntityAdapter<Employee>({
  selectId: user => user.userId,
});
export const employeeDetailAdapter = createEntityAdapter<EmployeeDetailResource>({
  selectId: employee => employee.userId,
});
