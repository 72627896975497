import { useMemo } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

import { messages as cashRegister } from '@cam/app/src/features/notifications/cashRegister';
import { messages as company } from '@cam/app/src/features/notifications/company';
import { messages as customers } from '@cam/app/src/features/notifications/customers';
import { createMessage } from '@cam/app/src/features/notifications/defaults';
import { messages as employees } from '@cam/app/src/features/notifications/employees';
import { messages as events } from '@cam/app/src/features/notifications/events';
import { messages as users } from '@cam/app/src/features/notifications/users';

export const createNotifications = (t: TFunction) =>
  ({
    cashRegister: cashRegister(t),
    company: company(t),
    customers: customers(t),
    employees: employees(t),
    events: events(t),
    users: users(t),
    defaults: createMessage({ success: true, failure: true }),
  } as const);

export const useNotifications = () => {
  const { t } = useTranslation();
  return useMemo(() => createNotifications(t), [t]);
};

export type NotificationsApi = ReturnType<typeof createNotifications>;
