import { GoogleOutlined } from '@ant-design/icons';
import { navigate, useLocation } from '@reach/router';
import { Row, Space } from 'antd';
import { signInWithPopup } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BetaSeparator } from '@cam/app/src/components/BetaLabel';
import { Routes } from '@cam/app/src/constants/routes';
import { Layout } from '@cam/app/src/features/oauth/Layout';
import { Button, Card, Title } from '@cam/atomic';
import { auth, provider } from '@cam/firebase/clientApp';

export const Login = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const state = useLocation().state as { redirectUrl: string };

  const handleLogin = () => {
    setIsLoading(true);
    signInWithPopup(auth, provider)
      .then(res => {
        if (!res) {
          setIsLoading(false);
        }

        if (!!res?.user) {
          navigate(state?.redirectUrl || Routes.DASHBOARD.BASE);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <Row>
        <Card>
          <Title level={1} size="xxl" theme="light">
            {t('signin:title')}
          </Title>
          <Row style={{ padding: '0 35%' }}>
            <BetaSeparator type="horizontal" style={{ marginBottom: 14 }} />
          </Row>
          <Button
            loading={isLoading}
            variant="primary"
            onClick={handleLogin}
            icon={<GoogleOutlined />}
          >
            {t('signin:buttons.signin.google')}
          </Button>
        </Card>
      </Row>
    </Layout>
  );
};
