import { configureStore, Action, Middleware, combineReducers } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import { createLogger } from 'redux-logger';
import { ThunkAction, ThunkMiddleware } from 'redux-thunk';

import { NotificationsApi } from '@cam/app/src/features/notifications';
import authReducer from '@cam/app/src/redux/Auth';
import { RootState } from '@cam/app/src/redux/reducer';
import { Service } from '@cam/app/src/redux/service';

export const __DEV__ = process.env.REACT_APP_DEBUG_ENABLED === 'true';
const middleware: Middleware[] = [dynamicMiddlewares];
if (false) {
  middleware.push(
    createLogger({
      predicate: (_, action) => !action.type.endsWith('reset'),
    })
  );
}

const store = configureStore({
  reducer: combineReducers({
    Auth: authReducer,
  }) as any, //eslint-disable-line @typescript-eslint/no-explicit-any
  devTools: __DEV__,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: {
        ignoredPaths: ['Auth.data'],
      },
    }),
    ...middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type ThunkExtra = {
  api: Service;
  t: TFunction;
  notifications: () => NotificationsApi;
};
export type AppThunk<T = void> = ThunkAction<T, RootState, ThunkExtra, Action<string>>;
export type AppMiddleware = ThunkMiddleware<RootState, Action<string>, ThunkExtra>;
export default store;
