import { RouteComponentProps } from '@reach/router';
import { cloneElement, isValidElement } from 'react';

type Props = RouteComponentProps & {
  component: JSX.Element;
  children?: React.ReactNode;
};

export const Route = ({ component, ...props }: Props) => {
  if (isValidElement(component)) {
    return cloneElement(component, props);
  }
  return null;
};

export const Root: React.FC = ({ children }) => <>{children}</>;
