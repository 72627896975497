export enum SESSION_STORAGE {
  COMPANY_ID = 'companyId',
}

const getItem = (key: SESSION_STORAGE) => sessionStorage.getItem(key);
const setItem = (key: SESSION_STORAGE, value: string) => sessionStorage.setItem(key, value);
const removeItem = (key: SESSION_STORAGE) => sessionStorage.removeItem(key);

export const getCompanyId = () => getItem(SESSION_STORAGE.COMPANY_ID);
export const setCompanyId = (companyId: string) => setItem(SESSION_STORAGE.COMPANY_ID, companyId);
export const removeCompanyId = () => removeItem(SESSION_STORAGE.COMPANY_ID);
