import {
  createSlice,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

type ResourceState<T> = {
  data: T | null;
  isInitialized: boolean;
  isLoading: boolean;
  hasError: boolean;
};

export const emptyResourceState: ResourceState<null> = {
  data: null,
  isInitialized: false,
  isLoading: false,
  hasError: false,
};

export const createResourceSlice = <
  T,
  Reducers extends SliceCaseReducers<ResourceState<T>> = SliceCaseReducers<ResourceState<T>>
>({
  name,
  initialState = emptyResourceState,
  reducers = {} as ValidateSliceCaseReducers<ResourceState<T>, Reducers>,
}: {
  name: string;
  initialState?: Partial<ResourceState<T>>;
  reducers?: ValidateSliceCaseReducers<ResourceState<T>, Reducers>;
}) =>
  createSlice({
    name,
    initialState: { ...emptyResourceState, ...initialState },
    reducers: {
      ...reducers,
      request: state => {
        state.isLoading = true;
      },
      success: (state, { payload }: PayloadAction<T | null>) => {
        state.isLoading = false;
        state.isInitialized = true;
        state.hasError = false;
        state.data = payload as Draft<T>;
      },
      failure: state => {
        state.isLoading = false;
        state.isInitialized = true;
        state.hasError = true;
      },
      reset: () => ({ ...emptyResourceState, ...initialState }),
    },
  });
