import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { App } from '@cam/app/src/App';
import { LoadingScreen } from '@cam/app/src/components/LoadingScreen';
import { theme } from '@cam/theme';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Suspense fallback={<LoadingScreen isLoading />}>
      <App />
    </Suspense>
  </ThemeProvider>,
  document.getElementById('root')
);
