import camBlackLogo from '@cam/app/src/images/logo/cam-logo-black.svg';
import camLogo from '@cam/app/src/images/logo/cam-logo-color.svg';
import camBlackLogoLongText from '@cam/app/src/images/logo/cam-logo-long-text-black.svg';
import camLogoLongText from '@cam/app/src/images/logo/cam-logo-long-text-color.svg';
import camBlackLogoText from '@cam/app/src/images/logo/cam-logo-text-black.svg';
import camLogoText from '@cam/app/src/images/logo/cam-logo-text-color.svg';

type LogoAssets = {
  blob: string;
  long: string;
  main: string;
};

type LogoType = 'color' | 'black';

export const logo: Record<LogoType, LogoAssets> = {
  color: {
    blob: camLogo,
    long: camLogoLongText,
    main: camLogoText,
  },
  black: {
    blob: camBlackLogo,
    long: camBlackLogoLongText,
    main: camBlackLogoText,
  },
};
