import { TFunction } from 'i18next';

import { DefaultCategories } from '@cam/firebase/resource/CashRegister';

export const getDefaultCategoriesTranslations = (
  t: TFunction
): Record<DefaultCategories, string> => ({
  EVENT: t('cashRegister:defaultCategories.event'),
  OTHER: t('cashRegister:defaultCategories.other'),
});
