import { ErrorBoundary as DefaultBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { LoadingScreen } from '@cam/app/src/components/LoadingScreen';
import { logout } from '@cam/app/src/features/oauth/utils';

const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <LoadingScreen
      title={t('common:messages.unknownError.title')}
      subtitle={t('common:messages.unknownError.subtitle')}
      primaryButton={{
        text: t('common:refresh'),
        onClick: resetErrorBoundary,
      }}
      secondaryButton={{
        text: t('common:userMenu.logout'),
        onClick: () => logout(),
      }}
    />
  );
};

export const ErrorBoundary: React.FC = ({ children }) => (
  <DefaultBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => window.location.reload()}
    onError={error => console.log(error)}
  >
    {children}
  </DefaultBoundary>
);
