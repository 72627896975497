import { combineReducers } from '@reduxjs/toolkit';

import {
  cashRegisterThunks,
  cashRegisterReducer,
  dateRangeReducer,
  fetchingAditionalCashRegisterListReducer,
  firebasePaginationActions,
  firebasePaginationReducer,
} from '@cam/app/src/redux/CashRegister/cashRegister';
import { categoriesReducer } from '@cam/app/src/redux/CashRegister/categories';
import { AppThunk } from '@cam/app/src/redux/store';

export const reset = (): AppThunk => dispatch => {
  dispatch(cashRegisterThunks.reset());
  dispatch(firebasePaginationActions.reset());
};

const reducer = combineReducers({
  cashRegisterList: cashRegisterReducer,
  firebasePagination: firebasePaginationReducer,
  fetchingAdditionalCashRegisterList: fetchingAditionalCashRegisterListReducer,
  dateRange: dateRangeReducer,
  categories: categoriesReducer,
});

export default reducer;
