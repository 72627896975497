import { generate } from '@ant-design/colors';
import { mapValues } from 'lodash';
import { tint } from 'polished';

import { BasePalette, FontSize } from '@cam/theme/types';

const createBasePalette = (base: BasePalette) => mapValues(base, color => generate(color));

const createNeutralPalette = (base: string) => {
  return [
    '#fff', // bg
    '#fafafa', // table header
    '#f5f5f5', // surface, hover
    tint(0.92, base), // divider
    tint(0.85, base), // border, hover
    tint(0.5, base), // disabled
    tint(0.25, base), // secondary
    base, // primary
    base, // title
    base, // unused
  ] as const;
};

const createExtraDarkPalette = (base: string) => {
  return [
    '#fff',
    tint(0.75, base),
    tint(0.5, base),
    tint(0.15, base),
    tint(0.08, base),
    base,
  ] as const;
};

export const createPalette = (base: BasePalette, neutralBase: string, extraDarkBase: string) => {
  const basePalette = createBasePalette(base);
  const neutral = createNeutralPalette(neutralBase);
  const extraDark = createExtraDarkPalette(extraDarkBase);

  const palette = { ...basePalette, neutral, extraDark } as const;
  const neutralPalette = {
    dark: {
      bg: palette.extraDark[5],
      surface: palette.extraDark[4],
      hover: palette.extraDark[3],
      divider: palette.extraDark[3],
      border: palette.extraDark[3],
      disabled: palette.extraDark[2],
      secondary: palette.extraDark[1],
      primary: palette.extraDark[0],
    },
    light: {
      bg: palette.neutral[0],
      tableHeader: palette.neutral[1],
      surface: palette.neutral[2],
      hover: palette.neutral[2],
      divider: palette.neutral[3],
      border: palette.neutral[4],
      disabled: palette.neutral[5],
      secondary: palette.neutral[6],
      primary: palette.neutral[7],
    },
  } as const;
  return { palette, neutralPalette };
};

export const createColors = ({
  palette,
  neutralPalette,
  primary = palette.blue[5],
  info = palette.blue[5],
}: ReturnType<typeof createPalette> & { primary?: string; info?: string }) => {
  return {
    neutral: neutralPalette,
    palette,
    black: 'rgba(0, 0, 0, 0.85)',
    white: 'rgb(255, 255, 255)',
    primary,
    info,
    success: palette.green[5],
    warning: palette.gold[5],
    brand: palette.orange[5],
    error: palette.red[5],
    lightError: palette.red[2],
  };
};

const breakpoints = {
  xs: '480px',
  sm: '576px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
} as const;

const fontSizes: Record<FontSize, number> = {
  sm: 13,
  base: 15,
  md: 17,
  lg: 19,
  xl: 22,
  xxl: 28,
};

export const getBaseTheme = () => {
  return {
    breakpoints,
    fontSizes,
  } as const;
};
