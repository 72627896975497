import { InvitationMessageResource } from '@cam/firebase/resource/Invitation';

export const invitationEmailBody = (token: string): InvitationMessageResource => ({
  subject: 'You have been invited to join Club Actions Manager',
  html: `
  <a
  href="https://${process.env.REACT_APP_INVITATION_BASE}?invitationToken=${token}"
  style="
    background-color: #cd6133;
    border: 1px solid #e6e6e8;
    border-radius: 18px;
    color: #fff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    width: 200px;
    -webkit-text-size-adjust: none;
    mso-hide: all;
  "
  target="_blank"
  data-saferedirecturl="https://www.google.com/url?q=https://${process.env.REACT_APP_INVITATION_BASE}?invitationToken=${token}"
  >Click me to join</a
>

`,
});
