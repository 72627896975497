import { Amount } from '@cam/firebase/resource/CashRegister';
import { Currency } from '@cam/firebase/resource/Common';

export enum RoleResource {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  NO_ACCESS = 'NO_ACCESS',
  VIEWER = 'VIEWER',
}

export enum TimeUnit {
  MD = 'MD',
  HOUR = 'HOUR',
}

export interface Salary {
  amount: Amount;
  perTimeUnit: TimeUnit;
}

export interface SalaryResource {
  salaryId: string;
  salary: Salary;
}

export enum BusinessType {
  CLUB = 'CLUB',
  BAR = 'BAR',
  OTHER = 'OTHER',
}

export interface CompanyList {
  companyId: string;
  companyName: string;
  businessType: BusinessType;
  currency: Currency;
  role?: RoleResource;
}

export enum Permission {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum Scope {
  COMPANY = 'COMPANY',
  EVENT = 'EVENT',
  REPORT = 'REPORT',
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
  SALARY = 'SALARY',
  CASH_REGISTER = 'CASH_REGISTER',
}

export interface AccessResource {
  permissionsMatrix: Access[];
}
export interface Access {
  permissions: Array<Permission>;
  scope: Scope;
}

export interface CompanyResource {
  address?: string;
  businessType: BusinessType;
  city?: string;
  companyId: string;
  country?: string;
  created: string;
  currencyCode?: string;
  description?: string;
  name: string;
  currency: Currency;
}

export interface CompanyUpdateResource {
  address?: string;
  businessType?: BusinessType;
  city?: string;
  country?: string;
  currencyCode?: string;
  description?: string;
  currency?: Currency;
  name?: string;
}

export interface CompanyCredentialsResource {
  businessType: BusinessType;
  name: string;
  currency: Currency;
}
