import { TFunction } from 'i18next';

import { createMessage } from '@cam/app/src/features/notifications/defaults';

export const messages = (t: TFunction) => ({
  createEvent: createMessage({
    success: t('events:messages.create.success') as string,
    failure: t('events:messages.create.failure') as string,
  }),
  updateEvent: createMessage({
    success: t('events:messages.update.success') as string,
    failure: t('events:messages.update.failure') as string,
  }),
  removeEvent: createMessage({
    success: t('events:messages.delete.success') as string,
    failure: t('events:messages.delete.failure') as string,
  }),
});
