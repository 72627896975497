import { combineReducers } from '@reduxjs/toolkit';

import { createBooleanSlice } from '@cam/redux/slices';

const reducerName = 'App';

export const {
  actions: { set: setSiderCollapsed, toggle: toggleSiderCollapsed },
  reducer: siderCollapsedReducer,
} = createBooleanSlice(`${reducerName}/siderCollapsed`, false);

export const {
  actions: { set: setSidePanelExpanded, toggle: toggleSidePanelExpanded },
  reducer: sidePanelExpandedReducer,
} = createBooleanSlice(`${reducerName}/sidePanelExpanded`, false);

export default combineReducers({
  sidePanelExpanded: sidePanelExpandedReducer,
  siderCollapsed: siderCollapsedReducer,
});
