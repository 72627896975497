import { combineReducers } from '@reduxjs/toolkit';

import App from '@cam/app/src/redux/App';
import Auth from '@cam/app/src/redux/Auth';
import CashRegister from '@cam/app/src/redux/CashRegister';
import Company from '@cam/app/src/redux/Company';
import Customers from '@cam/app/src/redux/Customers';
import Employees from '@cam/app/src/redux/Employees';
import Events from '@cam/app/src/redux/Events';
import Modals from '@cam/app/src/redux/Modals';
import Signup from '@cam/app/src/redux/Signup';
import Users from '@cam/app/src/redux/Users';

const rootReducer = combineReducers({
  App,
  Auth,
  CashRegister,
  Company,
  Customers,
  Employees,
  Events,
  Modals,
  Signup,
  Users,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
