import { RoleResource, SalaryResource } from '@cam/firebase/resource/Company';

export enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  SUSPENDED = 'SUSPENDED',
}
export interface Employee {
  displayName?: string | null;
  email?: string | null;
  job?: string;
  role: RoleResource;
  userId: string;
  status: EmployeeStatus;
  salaryId?: string;
}

export interface EmployeeResource extends Employee {
  companyId: string;
}

export interface EmployeeDetailResource extends Omit<Employee, 'salaryId'> {
  salary?: SalaryResource;
}
export interface UpdateEmployeeResource {
  job?: string;
  salaryId?: string;
  role: RoleResource;
}
export interface UpdateEmployee {
  job?: string;
  salary?: SalaryResource;
  role: RoleResource;
}

// INVITATION
export interface InvitationRequestResource {
  emails: Array<string>;
  role: RoleResource;
  job?: string;
}
