import { getFutureRangeFromPeriod, TimePeriod } from '@cam/app/src/components/RangePicker/util';
import { EventFormProps } from '@cam/app/src/features/events/create/form/types';
import { serializeFilter, sortMap } from '@cam/app/src/features/events/table/util';
import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { getCustomer } from '@cam/app/src/redux/Customers/selectors';
import { getEmployee, getPersonnel } from '@cam/app/src/redux/Employees/selectors';
import { eventListAdapter } from '@cam/app/src/redux/Events/adapters';
import { RootState } from '@cam/app/src/redux/reducer';
import { AppThunk } from '@cam/app/src/redux/store';
import { dateISOToTimestamp } from '@cam/app/src/utils/date';
import { FirebasePagination } from '@cam/firebase/resource/Common';
import {
  EmployeePaymentRow,
  EmployeePaymentTable,
  EventResource,
} from '@cam/firebase/resource/Event';
import { createBooleanSlice, createResourceSlice, createValueSlice } from '@cam/redux/slices';
import { createTableActions } from '@cam/redux/table';
import { createTableSelectors } from '@cam/redux/table/selectors';

export const reducerName = 'Events';

export const {
  actions: eventListActions,
  reducer: eventListReducer,
  thunks: eventListThunks,
} = createTableActions({
  reducerName: `${reducerName}/eventList`,
  adapter: eventListAdapter,
});

export const { actions: firebasePaginationActions, reducer: firebasePaginationReducer } =
  createResourceSlice<FirebasePagination<EventResource>>({
    name: `${reducerName}/firebasePagination`,
    initialState: { data: { pagination: [], lastPage: false } },
  });

export const {
  actions: { set: setFetchingAditionalEventList },
  reducer: fetchingAditionalEventListReducer,
} = createBooleanSlice(`${reducerName}/fetchingAditionalEventList`, false);

export const {
  actions: { set: setDateRange },
  reducer: dateRangeReducer,
} = createValueSlice(reducerName + '/dateRange', getFutureRangeFromPeriod(TimePeriod['30days']));

export const fetchEventList =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    const dateRange = getDateRange(getState());
    try {
      dispatch(eventListActions.dataActions.getDataRequest());
      const filter = eventListSelectors.getFilterSorterSerialized(getState());
      const { data, countByFilter, newFirebasePagination } = await api.Event.fetchEvents(
        companyId,
        filter,
        dateRange
      );
      dispatch(eventListThunks.setData(data, countByFilter, { override: true }));
      dispatch(firebasePaginationActions.success(newFirebasePagination));
    } catch (e) {
      console.log('errorek', e);
      dispatch(eventListActions.dataActions.getDataFailure());
    }
  };

export const fetchAdditionalEventList =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    const currentFirebasePagination = getFirebasePagination(getState()).data;
    const currentDataSource = eventListSelectors.dataSelectors.selectAll(getState());
    const dateRange = getDateRange(getState());
    try {
      dispatch(setFetchingAditionalEventList(true));
      const filter = eventListSelectors.getFilterSorterSerialized(getState());
      const { data, countByFilter, newFirebasePagination } = await api.Event.fetchEvents(
        companyId,
        filter,
        dateRange,
        currentFirebasePagination
      );
      dispatch(eventListThunks.setData([...currentDataSource, ...data], countByFilter));
      dispatch(firebasePaginationActions.success(newFirebasePagination));
    } catch (e) {
      console.log('errorek', e);
      dispatch(eventListActions.dataActions.getDataFailure());
    } finally {
      dispatch(setFetchingAditionalEventList(false));
    }
  };

export const createEvent =
  (event: EventFormProps): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      dispatch(eventListActions.dataActions.setIsCreating(true));
      const {
        productionManager: productionManagerId,
        personnel: personnelIds,
        customer: customerId,
        date,
        ...rest
      } = event;
      const companyId = getSelectedCompanyId(getState());
      const productionManager = getEmployee(getState(), productionManagerId);
      const personnel = (!!personnelIds && getPersonnel(getState(), personnelIds)) || undefined;
      const customer = (!!customerId && getCustomer(getState(), customerId)) || undefined;
      const employeesSalaryCash: EmployeePaymentTable = {};

      if (Array.isArray(personnelIds) && Array.isArray(personnel)) {
        const salaries = await api.Company.Salary.fetchSalaries(personnelIds);

        personnel.forEach(employee => {
          employeesSalaryCash[employee.userId] = {
            amount: 0,
            salary: salaries.find(salary => salary.salaryId === employee.salaryId),
          } as EmployeePaymentRow;
        });
      }

      productionManager &&
        (await api.Event.createEvent(companyId, {
          ...rest,
          productionManager,
          personnel,
          customer,
          date: dateISOToTimestamp(date),
          middle: {
            employeesSalaryCash,
            deposit: 0,
            rentSpaceCash: 0,
            rentSpaceCard: 0,
            salesCash: 0,
            salesCard: 0,
            invoice: 0,
          },
        }));
      notifications().events.createEvent.success();
      dispatch(fetchEventList(companyId));
      dispatch(eventListActions.dataActions.setIsCreating(false));
    } catch {
      notifications().events.createEvent.failure();
      dispatch(eventListActions.dataActions.setIsCreating(false));
    }
  };

// SELECTORS
export const getIsFetchingAdditionalEventList = (state: RootState) =>
  state.Events.fetchingAdditionalEventList;

export const eventListSelectors = createTableSelectors({
  adapter: eventListAdapter,
  selector: (state: RootState) => state.Events.eventList,
  serializeFilter,
  sortMap,
});

export const getIsCreating = (state: RootState) => state.Events.eventList.data.isCreating;

export const getFirebasePagination = (state: RootState) => state.Events.firebasePagination;

export const getDateRange = (state: RootState) => state.Events.dateRange;
