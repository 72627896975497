import { createSelector } from '@reduxjs/toolkit';

import { employeeDetailAdapter, employeesAdapter } from '@cam/app/src/redux/Employees/adapters';
import { RootState } from '@cam/app/src/redux/reducer';
import { RoleResource } from '@cam/firebase/resource/Company';
import { EmployeeStatus } from '@cam/firebase/resource/Employee';
import { createEntitySelectors, createTableSelectors } from '@cam/redux/table/selectors';

export const selectors = createTableSelectors({
  adapter: employeesAdapter,
  selector: (state: RootState) => state.Employees,
});

export const getEmployees = selectors.dataSelectors.selectAll;
export const getActiveEmployees = createSelector([getEmployees], employees =>
  employees.filter(employee => employee.status === EmployeeStatus.ACTIVE)
);

export const getPersonnel = createSelector(
  [getEmployees, (_, personnelIds: string[]) => personnelIds],
  (employees, personnelIds) => employees.filter(employee => personnelIds.includes(employee.userId))
);

export const getEmployee = selectors.dataSelectors.selectById;
export const getIsEmployeesInitialized = (state: RootState) => state.Employees.data.isInitialized;
export const getIsEmployeesLoading = (state: RootState) => state.Employees.data.isLoading;

// INVITES
export const getIsInvitingCompany = (state: RootState) => state.Employees.invites.inviteCompany;

export const getAcceptInvitationError = (state: RootState) =>
  state.Employees.invites.inviteCompanyError.error;

// DETAIL
export const employeeDetailSelectors = createEntitySelectors(
  (state: RootState) => state.Employees.detail,
  employeeDetailAdapter
);

export const getIsLoadingDetail = (state: RootState) => state.Employees.detail.isLoading;

export const getIsUpdatingDetail = (state: RootState) =>
  state.Employees.detail.updatingIds.length > 0;

export const getEmployeeDetail = employeeDetailSelectors.selectById;

export const getEmployeeDetailFormData = createSelector(
  [(state: RootState, employeeId: string) => getEmployeeDetail(state, employeeId)],
  employeeDetail => {
    if (!employeeDetail) {
      return {
        userId: '',
        displayName: '',
        email: '',
        role: RoleResource.ADMIN,
        status: EmployeeStatus.ACTIVE,
      };
    }

    return {
      displayName: employeeDetail.displayName,
      email: employeeDetail.email,
      role: employeeDetail.role,
      isSuspended: employeeDetail.status === EmployeeStatus.SUSPENDED,
      job: employeeDetail.job,
      salary: employeeDetail.salary,
    };
  }
);
