import { TFunction } from 'i18next';
import { addMiddleware, resetMiddlewares } from 'redux-dynamic-middlewares';
import createThunkMiddleware from 'redux-thunk';

import { createNotifications } from '@cam/app/src/features/notifications';
import { reset as resetCashRegister } from '@cam/app/src/redux/CashRegister';
import { setSelectedCompanyIdAction as setSelectedCompanyId } from '@cam/app/src/redux/Company';
import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { reset as resetCustomers } from '@cam/app/src/redux/Customers';
import { reset as resetEmployees } from '@cam/app/src/redux/Employees';
import { reset as resetEvents } from '@cam/app/src/redux/Events';
import reducer from '@cam/app/src/redux/reducer';
import service from '@cam/app/src/redux/service';
import appStore, { AppMiddleware, ThunkExtra } from '@cam/app/src/redux/store';
import { initI18next } from '@cam/app/src/utils/i18n';

const switchCompanyMiddleware: AppMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const companyId = getSelectedCompanyId(getState());
    if (action.type === setSelectedCompanyId.type && companyId !== action.payload) {
      dispatch(resetEvents());
      dispatch(resetCashRegister());
      dispatch(resetEmployees());
      dispatch(resetCustomers());
    }
    return next(action);
  };

const getThunkMiddleware = (t: TFunction) =>
  createThunkMiddleware.withExtraArgument<ThunkExtra>({
    api: service,
    t,
    notifications: () => createNotifications(t),
  });

const injectReducers = () => {
  appStore.replaceReducer(reducer);
};

export const enhanceStore = async () => {
  const t = await initI18next();
  injectReducers();
  await resetMiddlewares();
  addMiddleware(getThunkMiddleware(t), switchCompanyMiddleware);
};
