import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';

// eslint-disable-next-line import/no-namespace
import { orderDirectionMap } from '@cam/app/src/redux/utils';
import { dateISOToTimestamp, dateTimestampToISO, DateUtil } from '@cam/app/src/utils/date';
import { invitationEmailBody } from '@cam/app/src/utils/invitation/email';
import * as sessionStorage from '@cam/app/src/utils/sessionStorage';
import { StringKeys } from '@cam/app/src/utils/typescript';
import { db } from '@cam/firebase';
import { UpdateUserResource, UserResource } from '@cam/firebase/resource/Auth';
import {
  CashRegisterList,
  CashRegisterListResource,
  Category,
  PagedCashRegisterListFilterResourceSorted,
} from '@cam/firebase/resource/CashRegister';
import { FirebasePagination } from '@cam/firebase/resource/Common';
import {
  Access,
  CompanyCredentialsResource,
  CompanyList,
  CompanyResource,
  CompanyUpdateResource,
  RoleResource,
  Salary,
  SalaryResource,
} from '@cam/firebase/resource/Company';
import {
  Employee,
  EmployeeResource,
  EmployeeStatus,
  UpdateEmployeeResource,
} from '@cam/firebase/resource/Employee';
import {
  CreateEventResource,
  Customer,
  CustomerResource,
  EventDetailResource,
  EventList,
  EventResource,
  PagedEventListFilterResourceSorted,
} from '@cam/firebase/resource/Event';
import { Payment, PaymentResource } from '@cam/firebase/resource/Payment';

const service = {
  Company: {
    fetchCompanies: async (userId: string) => {
      const employeesQuery = query(
        db.Company.employee,
        where('userId', '==', userId),
        where('status', '!=', EmployeeStatus.SUSPENDED)
      );
      const userCompJunk = await getDocs(employeesQuery);
      const companyIds = userCompJunk.docs.map(document => document.get('companyId'));

      if (companyIds.length > 0) {
        const companiesQuery = query(db.Company.company, where('companyId', 'in', companyIds));
        const companies = await getDocs(companiesQuery);
        return companies.docs.map((document): CompanyList => {
          const data = document.data();
          const role = userCompJunk.docs
            .find(obj => obj.get('companyId') === data.companyId)
            ?.get('role');
          return {
            companyId: data.companyId,
            companyName: data.name,
            businessType: data.businessType,
            currency: data.currency,
            role,
          };
        });
      } else {
        return [];
      }
    },
    fetchCompany: async (companyId: string) => {
      const reponse = await getDoc(doc(db.Company.company, companyId));
      return reponse.data();
    },
    createCompany: async (companyId: string, credentials: CompanyCredentialsResource) => {
      const company: CompanyResource = {
        ...credentials,
        companyId,
        created: DateUtil.toISO(DateUtil.date()),
      };
      await setDoc(doc(db.Company.company, companyId), company);
      return true;
    },
    updateCompany: async (companyId: string, updatedCompany: CompanyUpdateResource) => {
      await updateDoc(doc(db.Company.company, companyId), updatedCompany);
      return true;
    },
    deleteCompany: async (companyId: string) => {
      // Delete employees and their access to this company
      const employeesResponse = await getDocs(
        query(db.Company.employee, where('companyId', '==', companyId))
      );
      await Promise.all(
        employeesResponse.docs.map(employee => {
          deleteDoc(doc(db.Company.employee, employee.id));
          return deleteDoc(doc(db.Company.access, companyId, 'users', employee.get('userId')));
        })
      );

      // Delete events connected to this company
      const eventsResponse = await getDocs(
        query(db.Event.events, where('companyId', '==', companyId))
      );
      await Promise.all(
        eventsResponse.docs.map(event => {
          return deleteDoc(doc(db.Event.events, event.id));
        })
      );

      // Delete company itself
      deleteDoc(doc(db.Company.company, companyId));

      return true;
    },
    Salary: {
      manageSalary: async (salaryResource: SalaryResource) => {
        const { salaryId, salary } = salaryResource;
        const docRef = !!salaryId ? doc(db.Company.salary, salaryId) : doc(db.Company.salary);

        const newSalary = {
          salaryId: docRef.id,
          salary,
        };

        await setDoc(docRef, newSalary, { merge: true });
        return docRef.id;
      },
      fetchSalary: async (salaryId: string) => {
        const docRef = doc(db.Company.salary, salaryId);
        const response = await getDoc(docRef);
        return response.data();
      },
      fetchSalaries: async (salaryIds: string[]) => {
        if (salaryIds.length <= 0) {
          return [];
        }

        const q = query(db.Company.salary, where('salaryId', 'in', salaryIds));
        const response = await getDocs(q);
        return response.docs.map(salary => salary.data());
      },
      updateSalary: async (salary: SalaryResource) => {
        const docRef = doc(db.Company.salary, salary.salaryId);
        await updateDoc(docRef, salary);
      },
      createSalary: async (salary: Salary) => {
        const docRef = doc(db.Company.salary);
        const newSalary = {
          salaryId: docRef.id,
          salary,
        };
        await setDoc(docRef, newSalary);
        return docRef.id;
      },
    },
  },
  CashRegister: {
    fetchCashRegisterList: async (
      companyId: string,
      filter: PagedCashRegisterListFilterResourceSorted,
      dateRange: Date[],
      firebasePagination?: FirebasePagination<CashRegisterListResource> | null
    ): Promise<{
      data: CashRegisterList[];
      countByFilter: number;
      newFirebasePagination: FirebasePagination<CashRegisterListResource>;
    }> => {
      const { filterAnd } = filter.filter;
      const sorter = filter.sortedPage;
      const direction = !!sorter.sorting[0]
        ? orderDirectionMap[sorter.sorting[0].direction]
        : undefined;

      // BASIC QUERY
      const queryArray = [orderBy('date', direction), limit(filter.sortedPage.size)];

      // FILTER
      if (filterAnd?.length === 1) {
        Object.keys(filterAnd[0]).forEach(filterBy => {
          const filterValue = filterAnd[0][filterBy];
          !!filterValue && queryArray.push(where(filterBy, '==', filterValue));
        });
      }

      // DATE RANGE
      queryArray.push(where('date', '>=', dateRange[0]));
      queryArray.push(where('date', '<=', dateRange[1]));

      // STARTING POINT
      const newPagination = !!firebasePagination ? [...firebasePagination.pagination] : [];
      let newLastPage = firebasePagination?.lastPage;

      if (newPagination.length > 0) {
        queryArray.push(startAfter(newPagination[newPagination.length - 1]));
      }

      const q = query(db.CashRegister.cashRegister(companyId), ...queryArray);
      const response = await getDocs(q);
      let result: CashRegisterList[] = [];
      if (!response.empty) {
        const lastVisible = response.docs[response.size - 1];
        newPagination[sorter.page - 1] = lastVisible;

        if (response.size < sorter.size) {
          newLastPage = true;
        }

        // RESULT
        result = response.docs.map((document): CashRegisterList => {
          const { date, cashRegisterBalance, cashRegisterCorrection, ...rest } = document.data();
          return {
            date: dateTimestampToISO(date),
            cashRegisterBalance: {
              ...cashRegisterBalance,
              date: dateTimestampToISO(cashRegisterBalance.date),
            },
            cashRegisterCorrection: {
              ...cashRegisterCorrection,
              date: dateTimestampToISO(cashRegisterCorrection.date),
            },
            ...rest,
          };
        });
      } else {
        newLastPage = true;
      }

      return {
        data: result,
        countByFilter: sorter.page * sorter.size,
        newFirebasePagination: { pagination: newPagination, lastPage: !!newLastPage },
      };
    },
    fetchCashRegisterDocsFilterBased: async (
      companyId: string,
      filter: { attribute: StringKeys<CashRegisterList>; value: string }
    ) => {
      const { attribute, value } = filter;
      const q = query(db.CashRegister.cashRegister(companyId), where(attribute, '==', value));
      const response = await getDocs(q);
      return response.docs;
    },
    updateCashRegisterRecord: async (
      companyId: string,
      updatedCashRegisterRecord: Partial<CashRegisterListResource>
    ) => {
      const docRef = doc(
        db.CashRegister.cashRegister(companyId),
        `${updatedCashRegisterRecord.cashRegisterRecordId}`
      );
      await updateDoc(docRef, updatedCashRegisterRecord);
      return true;
    },
    createCashRegisterRecord: async (
      companyId: string,
      cashRegisterRecord: Omit<CashRegisterListResource, 'cashRegisterRecordId'>
    ) => {
      const docRef = doc(db.CashRegister.cashRegister(companyId));
      await setDoc(docRef, { ...cashRegisterRecord, cashRegisterRecordId: docRef.id });
      return docRef.id;
    },
    deleteCashRegisterRecord: async (companyId: string, cashRegisterRecordId: string) => {
      const docRef = doc(db.CashRegister.cashRegister(companyId), cashRegisterRecordId);
      await deleteDoc(docRef);
      return true;
    },
    fetchCategories: async (companyId: string) => {
      const q = query(db.CashRegister.categories(companyId));
      const response = await getDocs(q);
      return response.docs.map(category => category.data());
    },
    createCategory: async (companyId: string, category: Category) => {
      const docRef = doc(db.CashRegister.categories(companyId), `${category.key}`);
      await setDoc(docRef, category);
      return true;
    },
    deleteCategory: async (companyId: string, categoryKey: string) => {
      const docRef = doc(db.CashRegister.categories(companyId), `${categoryKey}`);
      await deleteDoc(docRef);
      return true;
    },
  },
  Customers: {
    createCustomer: async (customer: CustomerResource) => {
      const { customerId } = customer;
      const docRef = doc(db.Customer.customer, `${customerId}`);
      await setDoc(docRef, customer);
      return true;
    },
    fetchCustomers: async (companyId: string) => {
      const customersQuery = query(db.Customer.customer, where('companyId', '==', companyId));
      const response = await getDocs(customersQuery);
      const customers = response.docs.map((document): Customer => {
        const obj = document.data();
        const { name, email, customerId, phone } = obj;
        return {
          customerId,
          name,
          email,
          phone,
        };
      });
      return customers;
    },
  },
  Employees: {
    fetchEmployees: async (companyId: string) => {
      const employeesQuery = query(db.Company.employee, where('companyId', '==', companyId));
      const response = await getDocs(employeesQuery);
      const employees = response.docs.map((document): Employee => {
        const obj = document.data();
        const { displayName, email, job, role, userId, status, salaryId } = obj;
        return {
          displayName,
          email,
          job,
          role,
          userId,
          status,
          salaryId,
        };
      });
      return employees;
    },
    fetchEmployeeDetail: async (userId: string, companyId: string) => {
      const docRef = doc(db.Company.employee, `${userId}_${companyId}`);
      const employee = await getDoc(docRef);
      return employee.data();
    },
    createEmployee: async (employee: EmployeeResource, access: Access[]) => {
      const { userId, companyId } = employee;
      const docRef = doc(db.Company.employee, `${userId}_${companyId}`);
      await setDoc(docRef, employee);

      const permssionMatrixDocRef = doc(db.Company.access, companyId, 'users', userId);
      await setDoc(permssionMatrixDocRef, { permissionsMatrix: access });
      return true;
    },
    removeEmployee: async (userId: string, companyId: string) => {
      await deleteDoc(doc(db.Company.employee, `${userId}_${companyId}`));
      await deleteDoc(doc(db.Company.access, companyId, 'users', userId));
      return true;
    },
    fetchEmployeeAccess: async (userId: string, companyId: string) => {
      const response = await getDoc(doc(db.Company.access, companyId, 'users', userId));
      return response.data();
    },
    updateEmployeeAccess: async (userId: string, companyId: string, access: Access[]) => {
      const permssionMatrixDocRef = doc(db.Company.access, companyId, 'users', userId);
      await updateDoc(permssionMatrixDocRef, { permissionsMatrix: access });
      return true;
    },
    suspendEmployee: async (userId: string, companyId: string) => {
      const docRef = doc(db.Company.employee, `${userId}_${companyId}`);
      await updateDoc(docRef, { status: EmployeeStatus.SUSPENDED });
      return true;
    },
    activateEmployee: async (userId: string, companyId: string) => {
      const docRef = doc(db.Company.employee, `${userId}_${companyId}`);
      await updateDoc(docRef, { status: EmployeeStatus.ACTIVE });
      return true;
    },
    updateEmployee: async (
      userId: string,
      companyId: string,
      updatedEmployee: UpdateEmployeeResource
    ) => {
      const docRef = doc(db.Company.employee, `${userId}_${companyId}`);
      await updateDoc(docRef, { ...updatedEmployee });
      return true;
    },
    inviteEmployee: async (companyId: string, email: string, role: RoleResource, job?: string) => {
      const docRef = doc(db.Company.employee, `${email}_${companyId}`);
      const invitedEmployee: EmployeeResource = {
        userId: email,
        companyId,
        email,
        role,
        job,
        status: EmployeeStatus.INVITED,
      };
      await setDoc(docRef, invitedEmployee);

      // INVITATION RECORD
      const invitationDocRef = doc(db.Invite.invitations);
      await setDoc(invitationDocRef, {
        companyId,
        userEmail: email,
        inviteId: invitationDocRef.id,
      });

      //  SEND EMAIL
      const mailDocRef = doc(db.Invite.mail);
      await setDoc(mailDocRef, {
        to: email,
        message: invitationEmailBody(invitationDocRef.id),
      });
      return true;
    },
    removeInvitation: async (userId: string, companyId: string) => {
      await deleteDoc(doc(db.Company.employee, `${userId}_${companyId}`));
      return true;
    },
    acceptInvitation: async (currentUserEmail: string, invitationId: string) => {
      const invitationDocRef = doc(db.Invite.invitations, invitationId);
      const response = await getDoc(invitationDocRef);
      if (currentUserEmail === response.get('userEmail')) {
        return response.data();
      }
      return;
    },
  },
  Event: {
    fetchEvents: async (
      companyId: string,
      filter: PagedEventListFilterResourceSorted,
      dateRange: Date[],
      firebasePagination?: FirebasePagination<EventResource> | null
    ): Promise<{
      data: EventList[];
      countByFilter: number;
      newFirebasePagination: FirebasePagination<EventResource>;
    }> => {
      const { filterAnd } = filter.filter;
      const sorter = filter.sortedPage;
      const direction = !!sorter.sorting[0]
        ? orderDirectionMap[sorter.sorting[0].direction]
        : undefined;

      // BASIC QUERY
      const queryArray = [orderBy('date', direction), limit(filter.sortedPage.size)];

      // FILTER
      if (filterAnd?.length === 1) {
        Object.keys(filterAnd[0]).forEach(filterBy => {
          const filterValue = filterAnd[0][filterBy];
          !!filterValue && queryArray.push(where(filterBy, '==', filterValue));
        });
      }

      // CURRENT COMPANY
      queryArray.push(where('companyId', '==', companyId));

      // DATE RANGE
      queryArray.push(where('date', '>=', dateRange[0]));
      queryArray.push(where('date', '<=', dateRange[1]));

      // STARTING POINT
      const newPagination = !!firebasePagination ? [...firebasePagination.pagination] : [];
      let newLastPage = firebasePagination?.lastPage;

      if (newPagination.length > 0) {
        queryArray.push(startAfter(newPagination[newPagination.length - 1]));
      }

      const q = query(db.Event.events, ...queryArray);
      const response = await getDocs(q);
      let result: EventList[] = [];
      if (!response.empty) {
        const lastVisible = response.docs[response.size - 1];
        newPagination[sorter.page - 1] = lastVisible;

        if (response.size < sorter.size) {
          newLastPage = true;
        }

        // RESULT
        result = response.docs.map((document): EventList => {
          const data = document.data();
          return {
            date: dateTimestampToISO(data.date),
            eventId: data.eventId,
            name: data.name,
            eventType: data.eventType,
            productionManager: data.productionManager,
            customer: data.customer,
            hosts: data.hosts,
          };
        });
      } else {
        newLastPage = true;
      }

      return {
        data: result,
        countByFilter: sorter.page * sorter.size,
        newFirebasePagination: { pagination: newPagination, lastPage: !!newLastPage },
      };
    },
    createEvent: async (companyId: string, eventValues: CreateEventResource) => {
      const docRef = doc(db.Event.events);
      const event: EventResource = {
        ...eventValues,
        companyId,
        eventId: docRef.id,
      };
      await setDoc(docRef, event, { merge: true });
      return true;
    },
    updateEvent: async (event: EventResource) => {
      const docRef = doc(db.Event.events, event.eventId);
      await setDoc(docRef, event);
      return true;
    },
    deleteEvent: async (eventId: string) => {
      const docRef = doc(db.Event.events, eventId);
      await deleteDoc(docRef);
      return true;
    },
    fetchEventDetail: async (eventId: string) => {
      const docRef = doc(db.Event.events, eventId);
      const response = await getDoc(docRef);
      const data = response.data();
      if (!!data) {
        const { date, productionManager, personnel, customer, ...rest } = data;

        return {
          date: dateTimestampToISO(date),
          productionManager: productionManager.userId,
          personnel: personnel?.map(employee => employee.userId),
          customer: customer?.customerId,
          ...rest,
        } as EventDetailResource;
      }
      return;
    },
  },
  Invite: {
    removeInvitation: async (inviteId: string) => {
      await deleteDoc(doc(db.Invite.invitations, inviteId));
      return true;
    },
  },
  Transaction: {
    createTransaction: async (companyId: string, payment: Payment) => {
      const { date, ...rest } = payment;
      const docRef = doc(db.Transaction.transaction);
      const transaction: PaymentResource = {
        ...rest,
        companyId,
        paymentId: docRef.id,
        date: dateISOToTimestamp(date),
      };
      await setDoc(docRef, transaction);
      return docRef.id;
    },
    updateTransaction: async (companyId: string, payment: Payment) => {
      const { date, paymentId, ...rest } = payment;
      const docRef = doc(db.Transaction.transaction, paymentId);
      const transaction: PaymentResource = {
        ...rest,
        paymentId,
        companyId,
        date: dateISOToTimestamp(date),
      };
      await updateDoc(docRef, transaction);
      return true;
    },
    deleteTransaction: async (paymentId: string) => {
      const docRef = doc(db.Transaction.transaction, paymentId);
      await deleteDoc(docRef);
      return true;
    },
    fetchTransaction: async (paymentId: string) => {
      const docRef = doc(db.Transaction.transaction, paymentId);
      const response = await getDoc(docRef);
      return response.data();
    },
  },
  SessionStorage: sessionStorage,
  Signup: {},
  Users: {
    fetchUser: async (userId: string) => {
      const response = await getDoc(doc(db.User.user, userId));
      return response.data();
    },
    createUser: async (user: UserResource) => {
      const docRef = doc(db.User.user, user.userId);
      await setDoc(docRef, user);
      return true;
    },
    updateUser: async (userId: string, data: UpdateUserResource) => {
      await setDoc(doc(db.User.user, userId), data, { merge: true });
      return true;
    },
  },
} as const;

export type Service = typeof service;
export default service;
