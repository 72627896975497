import { createEntityAdapter } from '@reduxjs/toolkit';

import { CashRegisterList, Category } from '@cam/firebase/resource/CashRegister';

export const reducerName = 'CashRegister';

export const cashRegisterAdapter = createEntityAdapter<CashRegisterList>({
  selectId: record => record.cashRegisterRecordId,
});
export const categoriesAdapter = createEntityAdapter<Category>({
  selectId: category => category.key,
});
