import { DateIOFormats, Unit } from '@date-io/core/IUtils';
import DayjsAdapter from '@date-io/dayjs';
import { DateType as DateIoGlobalType } from '@date-io/type';
import dayjs, { Dayjs } from 'dayjs'; // eslint-disable-line no-restricted-imports
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import localeDataPlugin from 'dayjs/plugin/localeData';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';
import weekdayPlugin from 'dayjs/plugin/weekday';
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear';
import weekYearPlugin from 'dayjs/plugin/weekYear';
import { Timestamp } from 'firebase/firestore';
import { capitalize } from 'lodash';

dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(weekdayPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(durationPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(weekOfYearPlugin);
dayjs.extend(weekYearPlugin);
dayjs.extend(advancedFormatPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(localizedFormatPlugin);

export const DateUtilCreator = DayjsAdapter;
export const DateUtil = new DayjsAdapter();

export type DateType = string | number | Date;
export type DateIoType = DateIoGlobalType;

export const formatDateUtil =
  (format: keyof DateIOFormats<string>) => (date?: DateType | DateIoType) => {
    if (date === null) {
      return '';
    }

    return DateUtil.format(DateUtil.date(date), format);
  };

export const formatDate = formatDateUtil('fullDate');
export const formatDateTime = formatDateUtil('fullDateTime');
export const formatDateTimeWithSeconds = (date?: DateType) => dayjs(date).format('ll LTS');

export const parseTime = (time: string) => {
  // support both single- and double-digit minutes
  let parsedTime = DateUtil.parse(time, 'H:mm');
  if (!DateUtil.isValid(parsedTime)) {
    parsedTime = DateUtil.parse(time, 'H:m');
  }
  return parsedTime;
};

export const getDiff = (start: DateType, end: DateType, unit: Unit) =>
  DateUtil.getDiff(DateUtil.date(start), DateUtil.date(end), unit);

export const formatDateNumeric = (date: DateType) =>
  DateUtil.formatByString(DateUtil.date(date), 'YYYY-MM-DD');

// Dayjs
export const formatIsoString = (date: DateType, keepOffset = false) => {
  if (keepOffset) {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  } else {
    return dayjs(date).toISOString();
  }
};

export const getWeekDay = (dayNumber: number) =>
  capitalize(dayjs().isoWeekday(dayNumber).format('dddd'));

export const getWeekDayShort = (dayNumber: number) => dayjs().isoWeekday(dayNumber).format('ddd');

export const getRelativeDate = (date: DateType) => dayjs(date).fromNow();

export const getUtcDayStart = () => dayjs.utc().startOf('day').format('YYYY-MM-DD');

export const getRangeOfDates = (from: Date | DateIoType, to: Date | DateIoType) => {
  let start = dayjs(from).startOf('day');
  const end = dayjs(to).startOf('day');
  const range: string[] = [];
  while (start.isSameOrBefore(end)) {
    range.push(start.format('YYYY-MM-DD'));
    start = start.add(1, 'day');
  }
  return range;
};

export const getRangeBeforeToday = (daysBefore: number) => {
  const today = dayjs().startOf('day');
  const fromDate = dayjs(today).subtract(daysBefore, 'days');
  return getRangeOfDates(fromDate, today);
};

export enum DurationUnits {
  MILLISECONDS = 'ms',
  SECONDS = 's',
}

export const getUnits = (duration: number) => ({
  [DurationUnits.MILLISECONDS]: duration,
  [DurationUnits.SECONDS]: dayjs.duration(duration, 'seconds').asMilliseconds(),
});

export const formatDuration = (
  duration: number,
  unit: DurationUnits = DurationUnits.MILLISECONDS
) => {
  const date = dayjs(getUnits(duration)[unit]).utc();

  return duration === -1 || !date.isValid() ? '-' : date.format('HH:mm:ss');
};

export const isInRange: (range: {
  start: Date;
  length: number;
  unit: Unit;
}) => (date: Date) => boolean =
  ({ start, length, unit }) =>
  date => {
    let startMoment: Dayjs, endMoment: Dayjs;
    if (length >= 0) {
      startMoment = dayjs(start).startOf('day');
      endMoment = dayjs(start).add(length, unit).endOf('day');
    } else {
      startMoment = dayjs(start).add(length, unit).startOf('day');
      endMoment = dayjs(start).endOf('day');
    }
    const dateMoment = dayjs(date);
    return dateMoment.isSameOrAfter(startMoment) && dateMoment.isSameOrBefore(endMoment);
  };

export const dateTimestampToISO = (date?: Timestamp) =>
  !!date ? DateUtil.toISO(DateUtil.date(date.toDate())) : DateUtil.date().toISOString();
export const dateISOToTimestamp = (date: DateType | DateIoType) =>
  Timestamp.fromDate(DateUtil.date(date).toDate());
