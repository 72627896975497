import { SerializedSorter, SerializedPagination } from '@cam/redux/table/selectors';

export const UNLIMITED_PAGE_SIZE = 10000;

export const serializeSortedPage = <T>(
  pagination: SerializedPagination,
  sorter: SerializedSorter<T>
) => ({
  ...pagination,
  sorting: sorter.sortedBy ? [{ field: sorter.sortedBy, direction: sorter.sortDir }] : [],
});

export const serializeFilters = <T>(...filters: T[]) => ({
  filter: {
    ...(filters.length > 0 && {
      filterAnd: filters.filter(
        filter => filter && Object.values(filter).some(val => typeof val !== 'undefined')
      ),
    }),
  },
});

export const serializeFilterSorter = <T, K>(
  filter: SerializedFilter<T>,
  sortedPage: SerializedSortedPage<K>
) => ({ ...filter, sortedPage });

export const defaultFilter: SerializedFilterSorter = {
  filter: {
    filterAnd: undefined,
  },
  sortedPage: { page: 1, size: 25, sorting: [] },
};

/* TS hacks to infer return type of a generic function */
/* eslint-disable @typescript-eslint/no-explicit-any */
class SerializedSortedPageWrapper<T> {
  wrapper = () => serializeSortedPage<T>(null as any, null as any);
}
type SerializedSortedPage<T = any> = ReturnType<SerializedSortedPageWrapper<T>['wrapper']>;

class SerializedFilterWrapper<T> {
  wrapper = () => serializeFilters<T>(null as any);
}
type SerializedFilter<T = any> = ReturnType<SerializedFilterWrapper<T>['wrapper']>;

class SerializedFilterSorterWrapper<T, K> {
  wrapper = () => serializeFilterSorter<T, K>(null as any, null as any);
}
type SerializedFilterSorter<T = any, K = any> = ReturnType<
  SerializedFilterSorterWrapper<T, K>['wrapper']
>;
/* eslint-enable @typescript-eslint/no-explicit-any */
