import { navigate } from '@reach/router';

import { Routes } from '@cam/app/src/constants/routes';
import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { fetchEmployees } from '@cam/app/src/redux/Employees';
import { employeeDetailAdapter, reducerName } from '@cam/app/src/redux/Employees/adapters';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { AppThunk } from '@cam/app/src/redux/store';
import { generatePermissionsMatrix } from '@cam/app/src/utils/permissionsMatrix';
import { SalaryResource } from '@cam/firebase/resource/Company';
import { UpdateEmployee } from '@cam/firebase/resource/Employee';
import { createEntitySlice } from '@cam/redux/slices';

export const { actions: employeeDetailActions, reducer: employeeDetailReducer } = createEntitySlice(
  `${reducerName}/employeeDetail`,
  employeeDetailAdapter
);

export const fetchEmployeeDetail =
  (userId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(employeeDetailActions.getDataRequest());
      const companyId = getSelectedCompanyId(getState());
      const employee = await api.Employees.fetchEmployeeDetail(userId, companyId);

      if (!!employee) {
        const { salaryId, ...rest } = employee;
        const salary = salaryId ? await api.Company.Salary.fetchSalary(salaryId) : undefined;
        dispatch(employeeDetailActions.getDataSuccess({ ...rest, salary }));
      } else {
        dispatch(employeeDetailActions.getDataFailure());
      }
    } catch {
    } finally {
      dispatch(employeeDetailActions.getDataFailure());
    }
  };

export const updateEmployee =
  (userId: string, updatedEmployee: UpdateEmployee, onSuccess?: () => void): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      dispatch(employeeDetailActions.updateDataRequest([userId]));
      const companyId = getSelectedCompanyId(getState());

      const { salary: salaryResource, ...restEmployee } = updatedEmployee;

      const salaryId = !!salaryResource
        ? await api.Company.Salary.manageSalary(salaryResource)
        : undefined;

      await api.Employees.updateEmployee(userId, companyId, {
        ...restEmployee,
        salaryId,
      });
      await api.Employees.updateEmployeeAccess(
        userId,
        companyId,
        generatePermissionsMatrix(restEmployee.role)
      );

      dispatch(
        employeeDetailActions.updateDataSuccess({
          entityIds: [userId],
          salary: salaryId
            ? ({ salary: salaryResource?.salary, salaryId } as SalaryResource)
            : undefined,
          ...restEmployee,
        })
      );

      dispatch(fetchEmployees());
      navigate(Routes.EMPLOYEES.BASE);
      notifications().users.updateProfile.success();
      onSuccess?.();
    } catch {
      dispatch(employeeDetailActions.updateDataFailure([userId]));
      notifications().users.updateProfile.failure();
    }
  };

export const activateEmployee =
  (userId: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      const companyId = getSelectedCompanyId(getState());
      await api.Employees.activateEmployee(userId, companyId);
      dispatch(fetchEmployeeDetail(userId));
      dispatch(fetchEmployees());
      notifications().employees.activateEmployee.success();
    } catch (error) {
      notifications().employees.activateEmployee.failure(error);
    } finally {
      dispatch(destroyModal());
    }
  };

export const suspendEmployee =
  (userId: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      const companyId = getSelectedCompanyId(getState());
      await api.Employees.suspendEmployee(userId, companyId);
      dispatch(fetchEmployeeDetail(userId));
      dispatch(fetchEmployees());
      notifications().employees.suspendEmployee.success();
    } catch (error) {
      console.log('🚀 ~ file: detail.ts ~ line 97 ~ error', error);
      notifications().employees.suspendEmployee.failure();
    } finally {
      dispatch(destroyModal());
    }
  };

export const thunks = {
  fetchEmployeeDetail,
  updateEmployee,
  activateEmployee,
  suspendEmployee,
};
