import { QueryDocumentSnapshot } from 'firebase/firestore';

export interface Price {
  amount: number;
  currency: Currency;
  dph?: number;
}

export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  USD = 'USD',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FirebasePagination<T> = {
  pagination: Array<QueryDocumentSnapshot<T>>;
  lastPage: boolean;
};
