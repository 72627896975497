import { Space, Row } from 'antd';
import styled from 'styled-components';

import { logo } from '@cam/app/src/components/Logo';
import { Text, Spin, Button } from '@cam/atomic';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 72px 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: ${props => props.theme.colors.neutral.dark.bg};
`;

const Logo = styled.img`
  width: 360px;
  max-height: 200px;
  object-fit: contain;
  justify-self: flex-start;
`;

export const LoadingScreen: React.FC<{
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  isLoading?: boolean;
  primaryButton?: { text: React.ReactNode; onClick: () => void };
  secondaryButton?: { text: React.ReactNode; onClick: () => void };
}> = ({ isLoading, primaryButton, secondaryButton, title, subtitle }) => (
  <Wrapper>
    <Row style={{ height: 'auto' }} align="top">
      <Logo src={logo.color.main} alt="logo" />
    </Row>
    <Space direction="vertical" style={{ height: 'auto', marginBottom: 32 }}>
      {title && (
        <Text theme="dark" size="xl">
          {title}
        </Text>
      )}

      {subtitle && (
        <Text theme="dark" size="md">
          {subtitle}
        </Text>
      )}
    </Space>

    {isLoading ? (
      <Spin color="brand" size="large" />
    ) : (
      <Space style={{ height: 'auto' }} size="large">
        {secondaryButton && (
          <Button variant="default" ghost size="large" onClick={secondaryButton.onClick}>
            {secondaryButton.text}
          </Button>
        )}
        {primaryButton && (
          <Button variant="primary" size="large" onClick={primaryButton.onClick}>
            {primaryButton.text}
          </Button>
        )}
      </Space>
    )}
  </Wrapper>
);
