import { PayloadAction, createSlice, Draft } from '@reduxjs/toolkit';
import { SorterResult } from 'antd/es/table/interface';

export const createSorter = <T>(name = '', initialState: SorterResult<T>) => {
  return createSlice({
    name: `${name}/sorter`,
    initialState,
    reducers: {
      setSorter: {
        reducer(state, { payload }: PayloadAction<SorterResult<T> | SorterResult<T>[]>) {
          if (!Array.isArray(payload) && payload.column && payload.order) {
            state.column = payload.column as Draft<typeof payload.column>;
            state.order = payload.order;
          } else {
            return initialState;
          }
        },
        prepare: (payload: SorterResult<T> | SorterResult<T>[]) => ({
          payload,
        }),
      },
      reset: {
        reducer: () => {
          return initialState;
        },
        prepare: () => ({
          payload: undefined,
        }),
      },
    },
  });
};
