import { customersAdapter } from '@cam/app/src/redux/Customers/adapters';
import { RootState } from '@cam/app/src/redux/reducer';
import { createTableSelectors } from '@cam/redux/table/selectors';

export const selectors = createTableSelectors({
  adapter: customersAdapter,
  selector: (state: RootState) => state.Customers,
});

export const getCustomers = selectors.dataSelectors.selectAll;
export const getCustomer = selectors.dataSelectors.selectById;

export const getIsCustomersInitialized = (state: RootState) => state.Customers.data.isInitialized;
export const getIsCustomersLoading = (state: RootState) => state.Customers.data.isLoading;
