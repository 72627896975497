import { createSelector } from '@reduxjs/toolkit';

import { fallbackLng } from '@cam/app/src/locales';
import { RootState } from '@cam/app/src/redux/reducer';

export const getLocale = (state: RootState) => state.Auth.user.data?.language || fallbackLng;

export const getToken = (state: RootState) => state.Auth.user.data?.getIdToken;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const getUser = (state: RootState) => state.Auth.user.data!;
export const getUserId = (state: RootState) => state.Auth.user.data?.uid || '';
export const getUserInfo = createSelector([getUser], user => ({
  displayName: user?.displayName,
  email: user?.email,
}));

export const getHasVerifiedEmail = createSelector([getUser], user => !!user?.emailVerified);

export const getIsLoading = (state: RootState) => state.Auth.user.isLoading;
export const getIsInitialized = (state: RootState) => state.Auth.user.isInitialized;
export const getHasError = (state: RootState) => state.Auth.user.hasError;

export const getIsAuthRequired = createSelector(
  [getUser, getIsLoading],
  (user, isLoading) => !isLoading && !user
);

export const getIsUpdatingUser = (state: RootState) => state.Auth.updatingUser;

export default {
  getToken,
  getUser,
  getIsAuthRequired,
  getIsLoading,
  getUserInfo,
  getUserId,
};
