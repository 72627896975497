import { Link as NavLink } from '@reach/router';
import { Typography } from 'antd';
import styled from 'styled-components';

// import { externalLinkIcon } from '@cam/app/src/components/CommonIcon';
import { ExtractProps } from '@cam/app/src/utils/typescript';
import { Icon } from '@cam/atomic';

export const TextLink = Typography.Link;
export { NavLink };

const StyledLink = styled(Typography.Link).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})<{ href: string }>``;

export const ExternalLink: React.FC<ExtractProps<typeof StyledLink> & { withIcon?: boolean }> = ({
  withIcon,
  children,
  ...props
}) => (
  <StyledLink {...props}>
    {children || props.href}
    {/* TODO @psenicka: ICON */}
    {/* {withIcon && <Icon icon={externalLinkIcon} left={6} />} */}
  </StyledLink>
);

export const OptionalLink: React.FC<{ onClick?: () => void }> = ({ children, onClick, ...props }) =>
  onClick ? (
    <TextLink onClick={onClick} {...props}>
      {children}
    </TextLink>
  ) : (
    <span {...props}>{children}</span>
  );
