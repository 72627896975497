import { getRangeFromPeriod, TimePeriod } from '@cam/app/src/components/RangePicker/util';
import { serializeFilter, sortMap } from '@cam/app/src/features/cashRegister/table/util';
import { cashRegisterAdapter, reducerName } from '@cam/app/src/redux/CashRegister/adapters';
import { RootState } from '@cam/app/src/redux/reducer';
import { AppThunk } from '@cam/app/src/redux/store';
import { CashRegisterListResource } from '@cam/firebase/resource/CashRegister';
import { FirebasePagination } from '@cam/firebase/resource/Common';
import { createBooleanSlice, createResourceSlice, createValueSlice } from '@cam/redux/slices';
import { createTableActions } from '@cam/redux/table';
import { createTableSelectors } from '@cam/redux/table/selectors';

export const {
  actions: cashRegisterActions,
  reducer: cashRegisterReducer,
  thunks: cashRegisterThunks,
} = createTableActions({
  reducerName: `${reducerName}/list`,
  adapter: cashRegisterAdapter,
});

export const { actions: firebasePaginationActions, reducer: firebasePaginationReducer } =
  createResourceSlice<FirebasePagination<CashRegisterListResource>>({
    name: `${reducerName}/firebasePagination`,
    initialState: { data: { pagination: [], lastPage: false } },
  });

export const {
  actions: { set: setFetchingAditionalCashRegisterList },
  reducer: fetchingAditionalCashRegisterListReducer,
} = createBooleanSlice(`${reducerName}/fetchingAditionalCashRegisterList`, false);

export const {
  actions: { set: setDateRange },
  reducer: dateRangeReducer,
} = createValueSlice(reducerName + '/dateRange', getRangeFromPeriod(TimePeriod['30days']));

export const fetchCashRegister =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    const dateRange = getDateRange(getState());
    try {
      dispatch(cashRegisterActions.dataActions.getDataRequest());
      const filter = cashRegisterListSelectors.getFilterSorterSerialized(getState());
      const { data, countByFilter, newFirebasePagination } =
        await api.CashRegister.fetchCashRegisterList(companyId, filter, dateRange);
      dispatch(cashRegisterThunks.setData(data, countByFilter, { override: true }));
      dispatch(firebasePaginationActions.success(newFirebasePagination));
    } catch (e) {
      console.log('errorek', e);
      dispatch(cashRegisterActions.dataActions.getDataFailure());
    }
  };

export const fetchAdditionalCashRegisterList =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api }) => {
    const currentFirebasePagination = getFirebasePagination(getState()).data;
    const currentDataSource = cashRegisterListSelectors.dataSelectors.selectAll(getState());
    const dateRange = getDateRange(getState());
    try {
      dispatch(setFetchingAditionalCashRegisterList(true));
      const filter = cashRegisterListSelectors.getFilterSorterSerialized(getState());
      const { data, countByFilter, newFirebasePagination } =
        await api.CashRegister.fetchCashRegisterList(
          companyId,
          filter,
          dateRange,
          currentFirebasePagination
        );
      dispatch(cashRegisterThunks.setData([...currentDataSource, ...data], countByFilter));
      dispatch(firebasePaginationActions.success(newFirebasePagination));
    } catch (e) {
      console.log('errorek', e);
      dispatch(cashRegisterActions.dataActions.getDataFailure());
    } finally {
      dispatch(setFetchingAditionalCashRegisterList(false));
    }
  };

// SELECTORS
export const getIsFetchingAdditionalCashRegisterList = (state: RootState) =>
  state.CashRegister.fetchingAdditionalCashRegisterList;

export const cashRegisterListSelectors = createTableSelectors({
  adapter: cashRegisterAdapter,
  selector: (state: RootState) => state.CashRegister.cashRegisterList,
  serializeFilter,
  sortMap,
});

export const getFirebasePagination = (state: RootState) => state.CashRegister.firebasePagination;

export const getDateRange = (state: RootState) => state.CashRegister.dateRange;
