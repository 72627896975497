import { createEntityAdapter } from '@reduxjs/toolkit';

import { EventDetailResource, EventList } from '@cam/firebase/resource/Event';

export const eventListAdapter = createEntityAdapter<EventList>({
  selectId: item => item.eventId,
});

export const eventDetailAdapter = createEntityAdapter<EventDetailResource>({
  selectId: item => item.eventId,
});
