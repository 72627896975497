import { combineReducers } from '@reduxjs/toolkit';

import { fallbackLng } from '@cam/app/src/locales';
import { AppThunk } from '@cam/app/src/redux/store';
import { auth } from '@cam/firebase/clientApp';
import { EnhancedFirebaseUserResource, UpdateUserResource } from '@cam/firebase/resource/Auth';
import { createBooleanSlice, createResourceSlice } from '@cam/redux/slices';

const reducerName = 'Auth';

const { actions: authActions, reducer: authReducer } =
  createResourceSlice<EnhancedFirebaseUserResource>({
    name: reducerName,
  });

const {
  actions: { set: setUpdatingUser },
  reducer: updatingUserReducer,
} = createBooleanSlice(`${reducerName}/updating`, false);

export const fetchCurrentUser =
  (): AppThunk =>
  async (dispatch, _, { api }) => {
    try {
      dispatch(authActions.request());
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentFirebaseUser = auth.currentUser!;
      const { email, displayName, uid } = currentFirebaseUser;

      const userFromDB = await api.Users.fetchUser(currentFirebaseUser.uid);

      if (!!userFromDB) {
        if (userFromDB.email !== email || userFromDB.displayName !== displayName) {
          await api.Users.updateUser(userFromDB.userId, {
            email,
            displayName,
          });
        }
        dispatch(authActions.success({ ...currentFirebaseUser, language: userFromDB.language }));
      } else {
        await api.Users.createUser({ email, displayName, language: fallbackLng, userId: uid });
        dispatch(authActions.success({ ...currentFirebaseUser, language: fallbackLng }));
      }
    } catch (e) {
      console.log('🚀 ~ file: index.ts ~ line 34 ~ e', e);
      dispatch(authActions.failure());
    }
  };

export const updateCurrentUser =
  (data: UpdateUserResource): AppThunk =>
  async (dispatch, _, { api, notifications }) => {
    try {
      dispatch(setUpdatingUser(true));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentFirebaseUser = auth.currentUser!;
      await api.Users.updateUser(currentFirebaseUser.uid, data);
      notifications().users.updateProfile.success();
      dispatch(authActions.success({ ...currentFirebaseUser, ...data }));
    } catch (e) {
      console.log('🚀 ~ file: index.ts ~ line 50 ~ e', e);
      notifications().users.updateProfile.failure();
    } finally {
      dispatch(setUpdatingUser(false));
    }
  };

export const reset = (): AppThunk => dispatch => {
  dispatch(authActions.reset());
};

export const thunks = {};

const reducer = combineReducers({
  updatingUser: updatingUserReducer,
  user: authReducer,
});

export default reducer;
