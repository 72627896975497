import { LinkProps, navigate, useLocation } from '@reach/router';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/es/button';
import { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { resolve, shouldNavigate } from '@cam/app/src/utils/browser';
import { ExtractProps } from '@cam/app/src/utils/typescript';

export type ButtonVariants = 'default' | 'primary' | 'link' | 'text' | 'critical' | 'ghost';

const StyledButton = styled(AntdButton)<{
  $iconRight: boolean;
  $inline?: boolean;
}>`
  && {
    ${props =>
      props.icon &&
      css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
      `}

    ${props =>
      props.$iconRight &&
      css`
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;

        span {
          :not(:first-child) {
            margin-left: 4px;
            margin-right: 8px;
          }
        }
      `}

    ${props =>
      props.$inline &&
      css`
        margin: 0;
        padding: 0;
        height: auto;
      `}
  }
`;

type ButtonLinkProps = Pick<LinkProps<object>, 'to' | 'replace' | 'state'>; // eslint-disable-line @typescript-eslint/ban-types
const useLinkProps = (router?: ButtonLinkProps, onClick?: ButtonProps['onClick']) => {
  const location = useLocation();
  return useMemo(() => {
    if (!router) return;
    const { to, state, replace } = router;
    const href: string = resolve(encodeURI(to), location.pathname);

    const handleClick: React.MouseEventHandler<HTMLElement> = event => {
      onClick?.(event);
      if (shouldNavigate(event)) {
        event.preventDefault();
        navigate(href, { state, replace });
      }
    };

    return { href, onClick: handleClick };
  }, [router, location.pathname, onClick]);
};

export type ButtonProps = Omit<AntdButtonProps, 'type'> & {
  iconRight?: boolean;
  inline?: boolean;
  router?: ButtonLinkProps;
  type?: never;
  variant?: ButtonVariants;
};

// eslint-disable-next-line react/display-name
const ButtonBase = forwardRef<HTMLElement, ButtonProps>(
  ({ children, variant, iconRight = false, inline, router, ...rest }, ref) => {
    const linkProps = useLinkProps(router, rest.onClick);
    const props: ExtractProps<typeof StyledButton> = {
      ref,
      $iconRight: iconRight,
      $inline: inline,
      ...rest,
      ...linkProps,
    };

    switch (variant) {
      case 'text':
        return (
          <StyledButton type="text" {...props}>
            {children}
          </StyledButton>
        );
      case 'ghost':
        return (
          <StyledButton type="ghost" {...props}>
            {children}
          </StyledButton>
        );
      case 'primary':
        return (
          <StyledButton type="primary" {...props}>
            {children}
          </StyledButton>
        );
      case 'link':
        return (
          <StyledButton type="link" {...props}>
            {children}
          </StyledButton>
        );
      case 'critical':
        return (
          <StyledButton type="primary" danger {...props}>
            {children}
          </StyledButton>
        );
      case 'default':
      default:
        return <StyledButton {...props}>{children}</StyledButton>;
    }
  }
);

// re-export static properties of antd button
export const Button = Object.assign(ButtonBase, {
  __ANT_BUTTON: AntdButton.__ANT_BUTTON, // this fixes tooltip over disabled button
});
