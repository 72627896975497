/* eslint-disable @typescript-eslint/no-explicit-any */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash';

import { StringKeys } from '@cam/app/src/utils/typescript';

type RawFilter = (string | number)[] | null | undefined;

export type FilterType<T> = Partial<
  Record<StringKeys<T>, RawFilter> & {
    visibleColumns: StringKeys<T>[];
  }
>;

export interface FilterState<T> {
  filter: FilterType<T>;
  filteredCount?: number;
}
export const createFilter = <T>(name: string, initialState: FilterType<T>) => {
  return createSlice({
    name: `${name}/filter`,
    initialState: { filter: initialState, filteredCount: 0 } as FilterState<T>,
    reducers: {
      setCount: {
        reducer: (state: any, { payload }: PayloadAction<number>) => {
          state.filteredCount = payload;
        },
        prepare: (payload: number) => ({
          payload,
        }),
      },
      setFilter: {
        reducer: (state: any, { payload }: PayloadAction<FilterType<T>>) => {
          state.filter = {
            visibleColumns: state.filter.visibleColumns,
            ...payload,
          };
        },
        prepare: (payload: FilterType<T>) => {
          // null means explicitly clicking Reset button  or selecting 0 columns on visible columns filter
          // in this case we want to reset columns to their initial state
          const visibleColumns =
            payload.visibleColumns === null ? initialState.visibleColumns : payload.visibleColumns;

          const visibleFilters = visibleColumns
            ? pick(payload, visibleColumns as StringKeys<T>[])
            : omit(payload, 'key');

          return {
            payload: {
              ...visibleFilters,
              ...(visibleColumns && { visibleColumns }),
            } as FilterType<T>,
          };
        },
      },
      resetFilter: {
        reducer: (
          state: any,
          { payload }: PayloadAction<{ key: string; value: string | number | null }>
        ) => {
          const newFilter = state.filter[payload.key]?.filter(
            (item: unknown) => payload.value !== item
          );
          if (newFilter && newFilter.length > 0) {
            state.filter[payload.key] = newFilter;
          } else {
            delete state.filter[payload.key];
          }
        },
        prepare: (payload: { key: string; value: string | number | null }) => ({
          payload,
        }),
      },
      reset: {
        reducer: (state: any, { payload }: PayloadAction<StringKeys<T>[] | undefined>) => {
          state.filter = {
            ...initialState,
            visibleColumns: payload || state.filter.visibleColumns,
          };
          state.filteredCount = 0;
        },
        prepare: (payload?: StringKeys<T>[] | undefined) => ({
          payload,
        }),
      },
    },
  });
};

export const pickFirst = <T = string>(value: RawFilter) =>
  value ? (value[0] as unknown as T) : undefined;

export const filterBoolean = (value: RawFilter) => (value ? value[0] === 'true' : undefined);

export const mapToEnum = <T>(source: RawFilter, type: { [s: string]: T } | ArrayLike<T>) => {
  const result =
    source && source.length > 0 ? (source.map(item => type[item]).filter(Boolean) as T[]) : null;
  return result?.length ? result : undefined;
};
