import { getDefaultCategories } from '@cam/app/src/features/cashRegister/categories/form/utils';
import { categoriesAdapter, reducerName } from '@cam/app/src/redux/CashRegister/adapters';
import { getSelectedCompanyId } from '@cam/app/src/redux/Company/selectors';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { RootState } from '@cam/app/src/redux/reducer';
import { AppThunk } from '@cam/app/src/redux/store';
import { Category } from '@cam/firebase/resource/CashRegister';
import { createEntitySlice } from '@cam/redux/slices';

export const { actions: categoriesActions, reducer: categoriesReducer } = createEntitySlice(
  `${reducerName}/categories`,
  categoriesAdapter
);

export const fetchCategories =
  (): AppThunk =>
  async (dispatch, getState, { api, t }) => {
    const companyId = getSelectedCompanyId(getState());
    try {
      dispatch(categoriesActions.getDataRequest());
      const defaultCategories = getDefaultCategories(t);
      const data = await api.CashRegister.fetchCategories(companyId);
      if (!!data) {
        dispatch(categoriesActions.getDataSuccess([...defaultCategories, ...data]));
      } else {
        dispatch(categoriesActions.getDataSuccess(defaultCategories));
      }
    } catch {
      dispatch(categoriesActions.getDataFailure());
    }
  };

export const createCategory =
  (category: Category): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    const companyId = getSelectedCompanyId(getState());
    try {
      dispatch(categoriesActions.setIsCreating(true));
      const isCreated = await api.CashRegister.createCategory(companyId, category);
      isCreated && dispatch(fetchCategories());
      notifications().cashRegister.createCategory.success();
      dispatch(categoriesActions.setIsCreating(false));
    } catch {
      notifications().cashRegister.createCategory.failure();
      dispatch(categoriesActions.setIsCreating(false));
    }
  };

export const deleteCategory =
  (categoryKey: string, fallbackCategoryKey: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    const companyId = getSelectedCompanyId(getState());
    try {
      const docs = await api.CashRegister.fetchCashRegisterDocsFilterBased(companyId, {
        attribute: 'categoryKey',
        value: categoryKey,
      });

      await Promise.all(
        docs.map(doc => {
          const data = doc.data();
          return api.CashRegister.updateCashRegisterRecord(companyId, {
            ...data,
            categoryKey: fallbackCategoryKey,
          });
        })
      );

      await api.CashRegister.deleteCategory(companyId, categoryKey);

      dispatch(fetchCategories());
      dispatch(categoriesActions.removeEntities([categoryKey]));

      notifications().cashRegister.deleteCategory.success();
    } catch {
      notifications().cashRegister.deleteCategory.failure();
    } finally {
      dispatch(destroyModal());
    }
  };

export const { selectAll, selectTotal, selectById, selectEntities, selectIds } =
  categoriesAdapter.getSelectors((state: RootState) => state.CashRegister.categories.data);

export const getCategories = selectAll;
export const getCategoriesKeys = selectIds;

export const getIsLoading = (state: RootState) => state.CashRegister.categories.isLoading;
export const getIsInitialized = (state: RootState) => state.CashRegister.categories.isLoading;
export const getIsCreating = (state: RootState) => state.CashRegister.categories.isCreating;
