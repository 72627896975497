import { navigate } from '@reach/router';
import { combineReducers } from '@reduxjs/toolkit';

import { Routes } from '@cam/app/src/constants/routes';
import { refreshToken } from '@cam/app/src/features/oauth/utils';
import { getUserId } from '@cam/app/src/redux/Auth/selectors';
import {
  companyAdapter,
  accessRigthsAdapter,
  companyInfoAdapter,
} from '@cam/app/src/redux/Company/adapters';
import { destroyModal } from '@cam/app/src/redux/Modals';
import { AppThunk } from '@cam/app/src/redux/store';
import { CompanyList, CompanyUpdateResource } from '@cam/firebase/resource/Company';
import { createEntitySlice, createValueSlice } from '@cam/redux/slices';

const reducerName = 'Company';

const { actions: accessRightsActions, reducer: accessRightsReducer } = createEntitySlice(
  `${reducerName}/accessRights`,
  accessRigthsAdapter
);

const { actions: companiesActions, reducer: companiesReducer } = createEntitySlice(
  `${reducerName}/companies`,
  companyAdapter
);

const { actions: companyInfoActions, reducer: companyInfoReducer } = createEntitySlice(
  `${reducerName}/companyInfo`,
  companyInfoAdapter
);

export const {
  actions: { set: setSelectedCompanyIdAction },
  reducer: selectedCompanyIdReducer,
} = createValueSlice(`${reducerName}/selectedCompanyId`, '');

export const fetchCompaniesList =
  (userId: string, initialCompanyId?: string | null): AppThunk =>
  async (dispatch, _, { api }) => {
    try {
      dispatch(companiesActions.getDataRequest());

      const data = await api.Company.fetchCompanies(userId);

      dispatch(companiesActions.getDataSuccess(data));

      if (data.length === 0 && !window.location.pathname.includes(Routes.INVITATION.BASE)) {
        if (!window.location.pathname.includes(Routes.SIGNUP.BASE)) {
          navigate(Routes.SIGNUP.BASE + window.location.search, { replace: true });
        }
        return; // prevent throwing error due to empty data
      }

      const savedCompanyId = api.SessionStorage.getCompanyId();
      const initialCompany = data.find(company => company.companyId === initialCompanyId);
      const savedCompany = data.find(company => company.companyId === savedCompanyId);
      const companyId = initialCompany?.companyId || savedCompany?.companyId || data[0].companyId;
      dispatch(setSelectedCompanyId(companyId));
    } catch (e) {
      console.log('🚀 ~ file: index.ts ~ line 61 ~ e', e);
      dispatch(companiesActions.getDataFailure());
    }
  };

export const fetchAccessRights =
  (userId: string, companyId: string): AppThunk =>
  async (dispatch, _, { api }) => {
    try {
      dispatch(accessRightsActions.getDataRequest());
      const data = await api.Employees.fetchEmployeeAccess(userId, companyId);
      if (!!data) {
        dispatch(accessRightsActions.getDataSuccess(data.permissionsMatrix));
      } else {
        dispatch(accessRightsActions.getDataFailure());
      }
    } catch {
      dispatch(accessRightsActions.getDataFailure());
    }
  };

export const setSelectedCompanyId =
  (companyId: string): AppThunk =>
  (dispatch, _, { api }) => {
    try {
      dispatch(setSelectedCompanyIdAction(companyId));
      api.SessionStorage.setCompanyId(companyId);
    } catch {}
  };

export const fetchCompanyInfo =
  (companyId: string): AppThunk =>
  async (dispatch, _, { api }) => {
    try {
      dispatch(companyInfoActions.getDataRequest());
      const data = await api.Company.fetchCompany(companyId);
      if (!!data) {
        dispatch(companyInfoActions.getDataSuccess(data));
      }
    } catch {
      dispatch(companyInfoActions.getDataFailure());
    }
  };

export const updateCompany =
  (companyId: string, credentials: CompanyUpdateResource): AppThunk =>
  async (dispatch, _, { api, notifications }) => {
    try {
      dispatch(companyInfoActions.updateDataRequest([companyId]));
      await api.Company.updateCompany(companyId, credentials);
      dispatch(
        companyInfoActions.updateDataSuccess({
          entityIds: [companyId],
          ...credentials,
        })
      );

      const { name, currency, businessType } = credentials;
      dispatch(
        companiesActions.updateDataSuccess({
          entityIds: [companyId],
          companyName: name,
          currency,
          businessType,
        })
      );
      notifications().company.updateCompany.success();
    } catch (error) {
      dispatch(companyInfoActions.updateDataFailure([companyId]));
      notifications().company.updateCompany.failure(error);
    }
  };

export const removeEmployee =
  (userId: string, company: CompanyList): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    try {
      const selectedCompanyId = getState().Company.selectedCompanyId;

      await api.Employees.removeEmployee(userId, company.companyId);

      dispatch(companiesActions.removeEntities([company.companyId]));

      if (company.companyId === selectedCompanyId) {
        navigate(Routes.DASHBOARD.BASE);
        window.location.reload();
      }
      notifications().users.removeAccess.success();
    } catch (error) {
      notifications().users.removeAccess.failure(error);
    } finally {
      dispatch(destroyModal());
    }
  };

export const deleteCompany =
  (companyId: string): AppThunk =>
  async (dispatch, getState, { api, notifications }) => {
    const userId = getUserId(getState());
    try {
      await api.Company.deleteCompany(companyId);
      dispatch(companiesActions.removeEntities([companyId]));
      notifications().company.deleteCompany.success();
      fetchCompaniesList(userId);
      navigate(Routes.DASHBOARD.BASE);
      window.location.reload();
    } catch (error) {
      notifications().company.deleteCompany.failure(error);
    } finally {
      dispatch(destroyModal());
    }
  };

export const thunks = {
  fetchAccessRights,
  fetchCompaniesList,
  fetchCompanyInfo,
  setSelectedCompanyId,
  updateCompany,
  deleteCompany,
  removeEmployee,
};

const combinedReducer = combineReducers({
  selectedCompanyId: selectedCompanyIdReducer,
  accessRights: accessRightsReducer,
  companies: companiesReducer,
  companyInfo: companyInfoReducer,
});

export default combinedReducer;
