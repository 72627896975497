import { StringKeys } from '@cam/app/src/utils/typescript';
import {
  CashRegisterList,
  CashRegisterListFilterResource,
  CashRegisterListSorted,
} from '@cam/firebase/resource/CashRegister';
import { filterBoolean, FilterType } from '@cam/redux/table/filter';

export const serializeFilter = (
  filter: FilterType<CashRegisterList>
): CashRegisterListFilterResource => ({
  categories: filter.categoryKey as string[],
  dph: filterBoolean(filter.dph),
});

export const sortMap: Partial<Record<StringKeys<CashRegisterList>, CashRegisterListSorted>> = {
  date: CashRegisterListSorted.DATE,
  amount: CashRegisterListSorted.AMOUNT,
  amountWithoutDph: CashRegisterListSorted.AMOUNT_WITHOUT_DPH,
  dph: CashRegisterListSorted.DPH,
};
